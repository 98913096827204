export const validateField = (
  ValidationType,
  Value,
  CustomFailMessage = undefined
) => {
  var value = Value == undefined ? "" : Value.trim();
  var retVal;
  var valid;
  switch (ValidationType) {
    case "name":
      valid = value.match(/^[a-zA-Z\s]+$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Cannot be left blank";
      break;
    case "url":
      valid = value.match(/^[a-zA-Z0-9]+$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Cannot be left blank";
      break;
    case "website":
      valid = value.match(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/i
      );
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid website";
      break;
    case "nonempty":
      valid = value.length >= 1;
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Cannot be left blank";
      break;
    case "first_name":
    case "last_name":
    case "location":
      valid = value.length >= 2;
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Should be at least two characters";
      break;
    case "search_term":
      valid = value.length >= 3;
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Should be at least three characters";
      break;
    case "email":
      valid = value.match(
        /^[a-z0-9](?!.*?[^\na-z0-9]{2}).*?[a-z0-9]@([\w-]+\.)+([\w]{2,})$/i
      );
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid email";
      break;
    case "password":
      valid = value.length >= 6;
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Password is too short";
      break;
    case "zipcode":
      valid = value.match(/^\d{5}$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid zipcode. eg: 94587";
      break;
    case "phone":
      valid = value.match(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4})$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid phone number. xxxxxxxxxx format";
      break;
    case "state":
      valid = value.match(/^[A-Za-z]{2,}([\s])?([A-Za-z]{2,})?$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid state name";
      break;
    case "city":
      valid = value.match(/^[a-zA-Z]{2,}(?:[\s-][a-zA-Z]{2,})*$/);
      retVal = valid
        ? ""
        : CustomFailMessage != undefined
        ? CustomFailMessage
        : "Please enter a valid city name";
      break;
    case undefined:
      retVal = "";
      break;
    default:
      retVal = "";
      break;
  }
  return retVal;
};
