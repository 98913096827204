const colors = {
  primary: "#00a87e",
  primaryLight: "#a3b7f7",
  secondary: "#fbbc05",
  secondaryLight: "#fde59b",
  blueLink: "#0645ad",
  grey: "#555",
  greyLight: "#afafaf",
};
export default colors;
