import { useLocation } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import styled from "styled-components";
import { Listing, ListingSkeleton } from "../components/Listing";
import { useNavigate } from "react-router-dom";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import { CiSliderHorizontal } from "react-icons/ci";
import { size } from "../utils/constants/css";
import Button from "../components/common/Button";
import { bindActionCreators } from "redux";
import { search } from "../store/actions";
import LocButton from "../components/LocationButton";

const FilterButton = styled.button`
  display: none;
  font-size: 1.1rem;

  @media (max-width: 768px) {
    display: block;
    border: none;
    background-color: #fff;
    cursor: pointer;
    color: #00a87e;
    font-size: 0.85rem;
  }
`;

const Outer = styled(OuterContainer)`
  @media screen and (max-width: ${size.tablet}px) {
    flex-direction: column;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  max-width: 1600px;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FilterContainer = styled.div`
  font-size: 0.85rem;
  @media (min-width: 769px) {
    min-width: 272px;
    align-self: flex-start;
    flex-basis: 272px;
    height: calc(100vh - 177px);
    overflow-y: auto;
    z-index: 10;
    margin-right: 20px;

    .apply-filter-button {
      display: none;
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    height: 0;
    background-color: #fff;
    z-index: 100;
    overflow-y: auto;

    &.expanded {
      height: 100vh !important;
    }
  }
`;

const FilterTitle = styled.h3`
  /* Add your styles for the filter title */
  /* ... */
  color: #00a87e;
`;

const CloseButton = styled.button`
  border: none;
  background-color: #fff;
  cursor: pointer;
  padding: 0px;
  color: #000;
`;

const FilterMobileHeader = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    position: sticky;
    top: 0;
    background-color: #fff;
    padding: 10px;
    z-index: 10;
  }
`;

const FilterMobileFooter = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    position: sticky;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 65px;
    background-color: #fff;
    padding: 10px;
    border-top: 1px solid #ecedf1;
    z-index: 1100;
  }
`;

const FilterInner = styled.div`
  padding: 16px;

  overflow-y: auto;
  border-color: rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;

  @media (min-width: 769px) {
    max-height: 100%;
  }

  @media (max-width: 768px) {
    overflow-y: visible;
  }
`;

const ItemConditionFilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > label {
    margin-top: 8px;
  }
`;

const NoResultsMessage = styled.div`
  display: flex;
  font-size: 1em;
  flex-grow: 1;
  flex-direction: column;
  & > p {
    text-align: center;
  }
`;

const SearchResultsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  column-gap: 35px;
  row-gap: 105px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${size.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 20px;
    row-gap: 70px;
  }
`;

const CategorySelect = styled.div`
  /* Add your category select styles here */
`;

const CategoryOption = styled.div`
  /* Add your category option styles here */
  /* Use the 'selected' prop to style the selected category option */
  cursor: pointer;
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  font-size: 14px;
`;

const SubcategoryOption = styled.div`
  /* Add your subcategory option styles here */
  /* Use the 'selected' prop to style the selected subcategory option */
  font-weight: ${(props) => (props.selected ? "bold" : "normal")};
  cursor: pointer;
  margin-left: 20px; /* Add indentation */
`;

const MobileFilter = styled.div`
  display: none;
  @media screen and (max-width: ${size.tablet}px) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;
function CategoryFilters({
  categories,
  handleCategorySelect,
  selectedCategory,
  handleSubcategorySelect,
  selectedSubcategory,
}) {
  return (
    <div>
      <CategorySelect>
        <CategoryOption
          selected={selectedCategory === null}
          onClick={() => handleCategorySelect(null)}
        >
          All Categories
        </CategoryOption>
        {Object.entries(categories).map(([_, category]) => (
          <CategoryOption
            key={category.id}
            selected={selectedCategory === category.id}
            onClick={() => handleCategorySelect(category.id)}
          >
            {category.name}
            {selectedCategory === category.id &&
              category.subcategories.map((subcategory) => (
                <SubcategoryOption
                  key={subcategory.id}
                  selected={selectedSubcategory === subcategory.id}
                  onClick={(e) => {
                    e.stopPropagation(); // Stop event propagation
                    handleSubcategorySelect(subcategory.id);
                  }}
                >
                  {subcategory.name}
                </SubcategoryOption>
              ))}
          </CategoryOption>
        ))}
      </CategorySelect>
    </div>
  );
}

const ItemConditionFilter = ({
  itemConditions,
  selectedConditions,
  handleConditionChange,
}) => {
  return (
    <ItemConditionFilterContainer>
      <h3 style={{ fontSize: "14px", fontWeight: "bold" }}>Condition</h3>
      {Object.entries(itemConditions).map(([condition, { id }]) => (
        <label key={id}>
          <input
            type="checkbox"
            value={id}
            checked={selectedConditions.includes(id)}
            onChange={() => handleConditionChange(id)}
            style={{ marginRight: "4px" }}
          />
          {condition}
        </label>
      ))}
    </ItemConditionFilterContainer>
  );
};

const Search = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [searchResults, setSearchResults] = useState([]);
  const [categories, setCategories] = useState(props.state.search.categories);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [itemConditions, setItemConditions] = useState(
    props.state.search.itemConditions
  );
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const handleFilterButtonClick = () => {
    console.log(expanded);
    setExpanded(!expanded);
  };

  // console.log(selectedConditions);
  const handleConditionChange = (conditionId) => {
    const isSelected = selectedConditions.includes(conditionId);

    if (isSelected) {
      setSelectedConditions(
        selectedConditions.filter((id) => id !== conditionId)
      );
    } else {
      setSelectedConditions([...selectedConditions, conditionId]);
    }
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
    setSelectedSubcategory(null);
  };

  const handleSubcategorySelect = (subcategoryId) => {
    setSelectedSubcategory(subcategoryId);
  };

  const handleApplyFilter = () => {
    setExpanded(false);
  };

  const handleFilterReset = () => {
    setSelectedConditions([]);
    setSelectedCategory(null);
    setSelectedSubcategory(null);
  };

  const handleFilterClose = () => {
    setExpanded(false);
  };

  useEffect(() => {
    if (categories.length === 0) {
      console.log("check");
      const result = request("POST", API_CONSTANT_MAP.get_categories_v2);
      result
        .then((result) => {
          console.log(result);
          if (result.data.success) {
            setCategories(result.data.categories);
          }
        })
        .catch(() => {});
    }
  }, []);

  console.log("Component rendering or re-rendering");

  useEffect(() => {
    console.log("Effect function running on mount");

    return () => {
      console.log("************* Cleanup function running on unmount ***");
      props.search({
        payload: { query: "" },
      });
    };
  }, []);

  useEffect(() => {
    if (itemConditions.length === 0) {
      const result = request("POST", API_CONSTANT_MAP.get_item_conditions);
      result
        .then((result) => {
          if (result.data.success) {
            setItemConditions(result.data.item_conditions);
          }
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    // Only execute the search when not expanded and the search pane is active.
    if (!expanded) {
      setIsLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const query = searchParams.get("q");
      setSearchQuery(query); // Update search query based on URL.
      console.log(selectedSubcategory);
      console.log(selectedCategory);
      // Perform the search only if there is a query or filters are applied.
      // if (
      //   query ||
      //   selectedCategory ||
      //   selectedSubcategory ||
      //   selectedConditions.length
      // ) {
      console.log(1);
      const result = request("POST", API_CONSTANT_MAP.search_postgres, {
        search_term: query,
        category: selectedCategory,
        subcategory: selectedSubcategory,
        zipcode: props.state?.search?.zipcode,
        distance: props.state?.search?.distance,
        sort_by: props.state?.search?.sortBy,
        item_conditions: selectedConditions,
      });

      result
        .then((result) => {
          if (result.data.success) {
            setSearchResults(result.data.search_results); // Update search results from response
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Failed to fetch search results:", error);
          setIsLoading(false);
        });
      // } else {
      //   // No query and no filters, clear results
      //   setSearchResults([]);
      //   setIsLoading(false);
      // }
    }

    // Cleanup function to clear results when component unmounts or conditions change.
  }, [
    location.search,
    selectedCategory,
    selectedSubcategory,
    selectedConditions,
    expanded,
    props.state.search.zipcode,
    props.state.search.distance,
    props.state.search.sortBy,
  ]);

  const openListing = (id) => {
    navigate(`/listing/details/${id}`);
  };

  return (
    <>
      <NavBar user={props.state.user} />
      <Outer>
        <SearchContainer>
          <MobileFilter style={{ marginBottom: "10px" }}>
            <FilterButton onClick={handleFilterButtonClick}>
              <span>
                <CiSliderHorizontal size={25} style={{ marginRight: "5px" }} />
                Sort & Filters
              </span>
            </FilterButton>
            <LocButton />
          </MobileFilter>

          <FilterContainer className={expanded ? "expanded" : ""}>
            <FilterMobileHeader>
              <CloseButton onClick={handleFilterReset}>Reset</CloseButton>
              <FilterTitle>Filter</FilterTitle>
              <CloseButton onClick={handleFilterClose}>Close</CloseButton>
            </FilterMobileHeader>
            <FilterInner>
              <CategoryFilters
                categories={categories}
                handleCategorySelect={handleCategorySelect}
                selectedCategory={selectedCategory}
                handleSubcategorySelect={handleSubcategorySelect}
                selectedSubcategory={selectedSubcategory}
              />
              <hr />
              <ItemConditionFilter
                itemConditions={itemConditions}
                selectedConditions={selectedConditions}
                handleConditionChange={handleConditionChange}
              />
            </FilterInner>
            <FilterMobileFooter>
              <Button
                onClick={handleApplyFilter}
                className="apply-filter-button"
                style={{
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                Apply Filter
              </Button>
            </FilterMobileFooter>
          </FilterContainer>
          <>
            {isLoading ? (
              <SearchResultsContainer>
                {Array(10)
                  .fill(0)
                  .map((_, i) => (
                    <ListingSkeleton id={i} title={true} />
                  ))}
              </SearchResultsContainer>
            ) : searchResults.length > 0 ? (
              <SearchResultsContainer>
                {searchResults.map((result) => (
                  <Listing
                    key={result.id}
                    id={result.id}
                    title={result.title}
                    imageUrl={result.image_url}
                    description={result.description}
                    location={result.location}
                    onClick={() => openListing(result.id)}
                  />
                ))}
              </SearchResultsContainer>
            ) : (
              <NoResultsMessage>
                <p
                  style={{ fontWeight: "bold", padding: "0px", margin: "10px" }}
                >
                  No Results Found
                </p>
                <p style={{ margin: "0px", padding: "0px" }}>
                  <p style={{ margin: "0px", padding: "0px" }}>
                    <p style={{ margin: "0px", padding: "0px" }}>
                      {searchQuery ? (
                        <>
                          No offers to swap <strong>{searchQuery}</strong>{" "}
                          currently available. Please try again or modify your
                          search.
                        </>
                      ) : (
                        "No offers currently available. Please enter a search term or modify your search criteria."
                      )}
                    </p>
                  </p>
                </p>
              </NoResultsMessage>
            )}
          </>
        </SearchContainer>
      </Outer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ search }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Search);
