import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import NavBar from "../components/layout/NavBar";
import OuterContainer from "../components/layout/OuterContainer";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import Loader from "../components/Loading";
import { Listing, ListingSkeleton } from "../components/Listing";
import { bindActionCreators } from "redux";
import { search, zipcodeResults, zipcodeDistChanged } from "../store/actions";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { size } from "../utils/constants/css";
import LocButton from "../components/LocationButton";

const ListingContainer = styled.div`
  display: ${(props) => (props.hasListings ? "grid" : "none")};
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  column-gap: 35px;
  row-gap: 105px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${size.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 20px;
    row-gap: 70px;
  }
`;

const Outer = styled(OuterContainer)`
  @media screen and (max-width: ${size.tablet}px) {
    flex-direction: column;
    margin-top: 95px;
  }
`;

const NoResultsMessage = styled.div`
  display: flex;
  font-size: 1em;
  flex-direction: column;
  & > p {
    text-align: center;
  }
`;

const StyledButton = styled.button`
  color: #fff;
  background-color: #00a87e;
  border-radius: 5px;
  font-size: 1rem;
  width: 100%;
  margin: 10px 7px;
  padding: 7px 7px;
  font-weight: 500;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  border: none;
  cursor: pointer;
`;

const LocationButton = styled(LocButton)`
  @media screen and (max-width: ${size.tablet}px) {
    display: flex;
    font-size: 0.85rem;
    max-width: 280px;
    position: absolute;
    left: 50%; // Center horizontally
    top: 95px; // Center vertically
    transform: translate(
      -50%,
      -50%
    ); // Adjust the position to be exactly center
    border-radius: 10px; // Adds rounded corners
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a shadow
    padding: 7px;
    background-color: #efefef;
  }
`;

const Home = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [listings, setListings] = useState(
    props.state.search.zipcodeResults || []
  );

  const [errorMsg, setErrorMsg] = useState();

  useEffect(() => {
    // -- fake call to make handle cold starts
    if (props.state.search.zipcodeDistChanged || listings.length <= 0) {
      request("POST", API_CONSTANT_MAP.get_listing_by_id, { id: 1 });
      request("POST", API_CONSTANT_MAP.get_zipcode_listings, {
        zipcode: 94568,
        distance: 40,
      });
      console.log("cold start");
    }
  }, []);

  const openListing = (id) => {
    navigate(`/listing/details/${id}`);
  };

  const handleSwapClick = () => {
    navigate(`/swap`);
  };

  useEffect(() => {
    console.log(`new zip code ${props.state.search.zipcode}`);
    console.log(listings.length);
    if (props.state.search.zipcodeDistChanged || listings.length <= 0) {
      setIsLoading(true);
      const result = request("POST", API_CONSTANT_MAP.get_zipcode_listings, {
        zipcode: props.state?.search?.zipcode,
        distance: props.state?.search?.distance,
      });
      result
        .then((result) => {
          if (result.data.success) {
            const parsedResults = JSON.parse(result.data.search_results);
            setListings(parsedResults);
            props.zipcodeResults({
              payload: { zipcodeResults: parsedResults },
            });
            props.zipcodeDistChanged({
              payload: { zipcodeDistChanged: false },
            });
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [props.state.search.zipcode, props.state.search.distance]);

  return (
    <>
      <NavBar user={props.state?.user}>
        <LocationButton />
      </NavBar>
      <Outer>
        <>
          {isLoading ? (
            <ListingContainer hasListings={true}>
              {Array(10)
                .fill(0)
                .map((_, i) => (
                  <ListingSkeleton key={i} id={i} title={true} />
                ))}
            </ListingContainer>
          ) : listings.length > 0 ? (
            <ListingContainer hasListings={listings.length > 0}>
              {listings.map((result) => (
                <Listing
                  key={result.id}
                  id={result.id}
                  title={result.title}
                  imageUrl={result.image_url}
                  description={result.description}
                  location={result.location}
                  onClick={() => openListing(result.id)}
                />
              ))}
            </ListingContainer>
          ) : (
            <>
              <NoResultsMessage>
                <p
                  style={{ fontWeight: "bold", padding: "0px", margin: "10px" }}
                >
                  No Results Found
                </p>
                <p
                  style={{ fontWeight: "500", padding: "0px", margin: "10px" }}
                >
                  Didn't quite find what you're looking for?
                </p>
                <p style={{ margin: "0px", padding: "0px" }}>
                  Post a listing in your area.
                </p>
                <StyledButton onClick={handleSwapClick}>
                  List your item
                </StyledButton>
              </NoResultsMessage>
            </>
          )}
        </>
      </Outer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ search, zipcodeResults, zipcodeDistChanged }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);
