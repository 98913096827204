import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signout } from "../store/actions";

function SignOut(props) {
  const navigate = useNavigate();
  useEffect(() => {
    props.signout();
    navigate("/");
  }, []);
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ signout }, dispatch);

export default connect(null, mapDispatchToProps)(SignOut);
