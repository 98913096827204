import React from "react";
import styled from "styled-components";
import colors from "../utils/constants/colors";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";

const Container = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Heading = styled.h1`
  color: ${colors.primary};
  font-size: 1.4em;
  margin-bottom: 20px;
`;

const Step = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 20px;
  margin-bottom: 20px;
`;

const StepNumber = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.primary};
  color: #fff;
  border-radius: 50%;
  width: 1.875em; // Converted from 30px
  height: 1.875em; // Converted from 30px
  font-size: 0.875em; // Converted from 14px
  flex-shrink: 0; // Prevents the item from shrinking smaller than its size
  flex-grow: 0; // Prevents the item from growing larger than its size
`;

const StepDescription = styled.p`
  font-size: 1em;
`;

const HowItWorks = (props) => {
  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Container>
          <Heading>
            Welcome to SwapThings, where swapping is made simple!
          </Heading>
          <Step>
            <StepNumber>1</StepNumber>
            <StepDescription>
              Joining SwapThings is a breeze. Sign up for your free account.
              {/* and */}
              {/* become part of our vibrant swapping community. */}
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>2</StepNumber>
            <StepDescription>
              Explore our diverse collection of used books. Discover items you'd
              love to swap for.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>3</StepNumber>
            <StepDescription>
              When you spot something you want, click "Swap your item" to
              initiate the exchange. Select the items you're offering in return.
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>4</StepNumber>
            <StepDescription>
              Swapping starts the conversation. Chat directly with the other
              user to agree on the details of the swap. Negotiate, discuss, and
              get creative!
            </StepDescription>
          </Step>
          <Step>
            <StepNumber>5</StepNumber>
            <StepDescription>
              Once both parties are happy, it's time to swap. Arrange the
              exchange of items, and voilà! You have exciting new items to
              enjoy.
            </StepDescription>
          </Step>
          {/* <Step>
            <StepNumber>6</StepNumber>
            <StepDescription>
              After the swap, you can rate your experience and share your
              thoughts with the community. Your feedback helps maintain the
              trust and positivity within our swapping family.
            </StepDescription>
          </Step> */}
          <p>
            {/* Join SwapThings today and unlock a world of sustainable swapping. */}
            Let's make the most of our items. Happy swapping!
          </p>
        </Container>
      </OuterContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(HowItWorks);
