import { Link, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RoundedImage from "../../RoundedImage";
import unknown_gw from "../../../assets/unknown_gw.png";
import { BallBeat } from "react-pure-loaders";
import {
  CiChat2,
  CiHeart,
  CiShoppingTag,
  CiHome,
  CiPaperplane,
} from "react-icons/ci";

import { SlMenu } from "react-icons/sl";
import {
  AiOutlineSetting,
  AiOutlineHome,
  AiOutlineLogin,
} from "react-icons/ai";

import { BsPerson } from "react-icons/bs";
import colors from "../../../utils/constants/colors";
import { CiLogout } from "react-icons/ci";
import { FaMapMarkerAlt } from "react-icons/fa";
import { size } from "../../../utils/constants/css";
import Modal from "../../Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  search,
  zipcode,
  distance,
  categories,
  sortBy,
  itemConditions,
  location,
  zipcodeDistChanged,
} from "../../../store/actions";
import DropDwn from "../../DropDown";
import { Label } from "../../../styles/Shared";
import Button from "../../common/Button";
import { request } from "../../../services/networking/request";
import { API_CONSTANT_MAP } from "../../../utils/constants/endpoints";

const NavWrapper = styled.div`
  position: fixed; /* Relative positioning for child absolute components */
  top: 0;
  width: 100%;
  z-index: 100;
`;

const NavContainer = styled.nav`
  // position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #ecedf1;
  height: 80px;
  padding: 0 20px;

  @media screen and (max-width: ${size.tablet}px) {
    flex-direction: row;
    height: auto;
    padding: 0px;
    height: 70px;
    position: relative;
  }
`;

const SearchGroup = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  max-width: 600px;
  margin: 0 20px;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: ${size.tablet}px) {
    width: 100px;
    height: 40px;
    margin: 0 5px;
    margin: 20px 0;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 25px;
  padding: 0px 20px;
  background-color: #f5f5f6;
  transition: all 0.3s ease-in-out;
  height: 44px;
  flex-grow: 1;

  max-width: 500px;
  border: 1px solid #ecedf1;

  &.focused {
    border: 0.75px solid #222;
    background-color: #fff;
  }
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
  flex: 1;
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: ${size.tablet}px) {
    display: none;
  }
`;

const IconMain = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 5px;
  padding: 5px 5px;

  & > span {
    margin: 0px;
    font-size: 14px;
  }
  &:hover {
    background-color: #f0f0f0;
  }

  @media screen and (max-width: ${size.tablet}px) {
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;

    & > span {
      margin-right: 10px;
      font-size: 14px;
    }

    &:hover {
      background-color: #fff;
    }
  }
`;

const LogoMain = styled.h1`
  color: #00a87e;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;

const DropdownMain = styled.div`
  position: absolute;
  top: 80px;
  right: 10px;
  min-width: 150px;
  padding: 0.5rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0 4px 20px rgb(34 34 34 / 0.3);
  border-radius: 5px;
  display: ${(props) => (props.visible ? "block" : "none")};
`;

const DropdownItemMain = styled.a`
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 3px;
  transition: background 100ms;
  padding: 0.1rem;
  font-size: 0.8rem;
  font-weight: 500;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);

  &:hover {
    background-color: #f6f6f6;
  }
`;

const DropdownItemIcon = styled.span`
  padding-right: 0.5rem;
  $:hover {
    filter: none;
    text-decoration: none;
    color: #000;
  }
`;

const StyledNavItem = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 10px 10px;
  font-weight: 500;
  position: relative;
`;

const MobileIcon = styled.div`
  display: none;
  padding: 10px 10px;
  @media screen and (max-width: ${size.tablet}px) {
    display: block;
    cursor: pointer;
  }
`;

const NavBtn = styled.nav`
  display: flex;
  align-items: center;
  color: #fff;
  & > * {
    margin: 5px;
  }

  @media screen and (max-width: ${size.tablet}px) {
    display: none;
  }
`;

const NavBtnLink = styled(Link)`
  border-radius: 5px;
  background: #000;
  white-space: nowrap;
  padding: 5px 10px;
  font-size: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  tansition: all 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  font-weight: 500;

  &:hover {
    tansition: all 0.2s ease-in-out;
    background: #333;
    color: #fff;
  }
`;

const NavBtnLinkNoBg = styled(NavBtnLink)`
  background: #fff;
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);

  &:hover {
    background: #eee;
    color: #000;
  }
`;

const SearchIcon = styled.i`
  color: #a0a0a0;
  font-size: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: 500;
  background-color: #ffd2d2;
  font-size: 0.9rem;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
`;

const MobileMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;
  height: 100%;
  background-color: #fff;
  z-index: 9999;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? "translateX(0)" : "translateX(-100%)"};
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 10px;

  & > * {
    margin: 10px 0;
    text-align: center;
  }
`;

const MobileMenuBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: ${({ isOpen }) => (isOpen ? "100%" : "0%")};
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
`;

const CloseButton = styled.a`
  position: absolute;
  right: 20px;
  top: 20px;
  width: 20px;
  height: 20px;
  cursor: pointer;

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 21px;
    width: 2px;
    background-color: #fff;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
`;

const MobileMenuProfile = styled.div`
  height: 150px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const MobileMenuActions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

const Line = styled.hr`
  border: none;
  border-top: 1px solid #666;
  margin: 1em 0;
`;

const LocationButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #00a87e;
  font-size: 1.1rem;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: 8px; // Space between icon and text
  }

  @media screen and (max-width: ${size.tablet}px) {
    display: none;
  }
`;

const NavLink = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: red;
  padding: 10px 10px;
  font-weight: 500;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  &.active {
    border-bottom: 3px solid red;
  }
`;

// const NotificationBubble = styled.span`
//   background-color: red;
//   color: white;
//   font-size: 0.6rem;
//   padding: 6px 3px;
//   border-radius: 50%;
//   position: absolute;
//   top: 17px;
//   right: 17px;
//   transform: translate(50%, -50%);
//   z-index: 10;
// `;

const NotificationBubble = styled.span`
  background-color: #ff6b6b; // A lighter red color
  color: white;
  font-size: 0.5rem;
  width: 20px; // Ensures the bubble is wide enough for two digits
  height: 20px; // Same as width to make it perfectly round
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%; // Makes it perfectly round
  position: absolute;
  top: 17px;
  right: 17px;
  transform: translate(50%, -50%); // Adjust this to align the bubble as needed
  z-index: 10;
  padding: 0; // No padding needed due to flexbox centering
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Optional: Adds a slight shadow for depth
  font-weight: bold; // Makes the number more legible
`;

const NavBar = ({ className, ...props }) => {
  const [query, setQuery] = useState(props.state.search.query);
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [zip, setZip] = useState(props.state.search.zipcode);
  const [distance, setDistance] = useState(props.state.search.distance);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [sortOption, setSortOption] = useState(props.state.search.sortBy);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    console.log("**");
    setQuery(props.state.search.query);
  }, [props.state.search.query]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  function handleDistance(value) {
    setDistance(value);
  }

  const handleSearchFocus = () => {
    setIsSearchFocused(true);
  };

  const handleSearchBlur = () => {
    setIsSearchFocused(false);
  };

  const handleSearch = (query) => {
    props.search({
      payload: { query: query },
    });
    navigate(`/search?q=${query}`);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearch(event.target.value);
    }
  };

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleZipCode = (e) => {
    setZip(e.target.value);
  };

  const handleFilterApply = () => {
    setIsLoading(true); // Start loading
    setErrorMessage(""); // Reset any existing errors

    const result = request("POST", API_CONSTANT_MAP.validate_zipcode, {
      zipcode: zip,
    });

    result
      .then((result) => {
        console.log(result);
        setIsLoading(false); // Stop loading regardless of outcome
        if (result.data.success) {
          console.log(result.data.data.city);
          props.zipcode({
            payload: { zipcode: zip },
          });
          props.distance({
            payload: { distance: distance },
          });
          props.sortBy({
            payload: { sortBy: sortOption },
          });
          props.location({
            payload: { location: result.data.data.city },
          });

          props.zipcodeDistChanged({
            payload: {
              zipcodeDistChanged:
                props.state.search.zipcode !== zip ||
                props.state.search.distance !== distance,
            },
          });

          setShowFilter(false); // Possibly hide filter options after application
        } else {
          setErrorMessage(result.data.error.message); // Show error message from response
        }
      })
      .catch((error) => {
        setIsLoading(false); // Ensure loading is stopped in case of error
        console.error("Error applying filters:", error);
        setErrorMessage("Failed to apply filters."); // Set a generic error message
      });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const dropdown = document.getElementById("dropdown");
      if (event.target.closest("#dropdown") !== dropdown && isDropdownVisible) {
        toggleDropdown();
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      console.log("enddd");
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    if (!props.state.search.categories) {
      const result = request("POST", API_CONSTANT_MAP.get_categories_v2);
      result
        .then((result) => {
          if (result.data.success) {
            props.categories({
              payload: { categories: result.data.categories },
            });
          }
        })
        .catch(() => {});
    }
  }, []);

  useEffect(() => {
    if (!props.state.search.categories) {
      const result = request("POST", API_CONSTANT_MAP.get_item_conditions);
      result
        .then((result) => {
          if (result.data.success) {
            props.itemConditions({
              payload: { itemConditions: result.data.item_conditions },
            });
          }
        })
        .catch(() => {});
    }
  }, []);

  const onSortChange = (option) => {
    setSortOption(option);
  };

  return (
    <NavWrapper>
      <NavContainer className={className}>
        <>
          <NavItem path="/home" content={<LogoBuilder logotext={"Swap"} />} />
        </>
        <SearchGroup>
          <SearchContainer className={isSearchFocused ? "focused" : ""}>
            <SearchInput
              type="text"
              placeholder="Search"
              value={query}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onChange={(e) => setQuery(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            {/* <SearchIcon onClick={handleSearch} /> */}
          </SearchContainer>

          <LocationButton onClick={handleFilter}>
            <FaMapMarkerAlt size={20} />
            <span>{`${props.state.search.location}: ${props.state.search.distance} mi`}</span>
          </LocationButton>

          <SortAndFilterModal
            isOpen={showFilter}
            onClose={handleFilter}
            zip={zip}
            handleZipCode={handleZipCode}
            distance={distance}
            handleDistance={handleDistance}
            handleFilterApply={handleFilterApply}
            handleSort={onSortChange}
            sortOption={sortOption}
            errorMessage={errorMessage}
            isLoading={isLoading}
          />
        </SearchGroup>

        <MobileIcon onClick={handleMobileMenu}>
          <SlMenu size={20} />
        </MobileIcon>
        <MobileMenuBackdrop isOpen={isMobileMenuOpen}>
          <CloseButton onClick={handleMobileMenu} />
        </MobileMenuBackdrop>
        <MobileMenu isOpen={isMobileMenuOpen}>
          {props.user.token && (
            <>
              <MobileMenuProfile>
                <RoundedImage
                  imageHeight={60}
                  imageWidth={60}
                  borderRadiusPct={50}
                  image={props.user?.avatar ? props.user.avatar : unknown_gw}
                />
                <NavItem
                  path="/account"
                  content={<IconBuilder icon_text={"View Profile"} />}
                />
              </MobileMenuProfile>
              <Line />
            </>
          )}
          <MobileMenuActions>
            {props.user.token ? (
              <>
                <NavBtnLink
                  to="/swap"
                  style={{
                    color: "#fff",
                    backgroundColor: "#00a87e",
                    borderRadius: "5px",
                    width: "100%",
                    margin: "10px 0px",
                  }}
                >
                  List your item
                </NavBtnLink>
                <NavItem
                  path="/"
                  content={
                    <IconBuilder
                      icon={<CiHome size={20} />}
                      icon_text={"Home"}
                    />
                  }
                />
                <NavItem
                  path="/favourites"
                  content={
                    <IconBuilder
                      icon={<CiHeart size={26} />}
                      icon_text={"Saved"}
                    />
                  }
                />
                <NavItem
                  path="/inbox"
                  content={
                    <IconBuilder
                      icon={<CiChat2 size={22} />}
                      icon_text={"Inbox"}
                    />
                  }
                  count={props.state.user.unread_message_count}
                />
                <NavItem
                  path="/listings"
                  content={
                    <IconBuilder
                      icon={<CiShoppingTag size={20} />}
                      icon_text={"My Listings"}
                    />
                  }
                />
                <NavItem
                  path="/howitworks"
                  content={
                    <IconBuilder
                      icon={<CiPaperplane size={20} />}
                      icon_text={"How it works"}
                    />
                  }
                />
                <NavItem
                  path="/signout"
                  content={
                    <IconBuilder
                      icon={<CiLogout size={20} />}
                      icon_text={"Sign Out"}
                    />
                  }
                />
              </>
            ) : (
              <>
                <NavBtnLink
                  to="/swap"
                  style={{
                    color: "#fff",
                    backgroundColor: "#00a87e",
                    borderRadius: "5px",
                    width: "100%",
                    margin: "10px 0px",
                  }}
                >
                  List your item
                </NavBtnLink>
                <NavItem
                  path="/"
                  onClick={handleMobileMenu}
                  content={
                    <IconBuilder
                      icon={<AiOutlineHome size={20} />}
                      icon_text={"Home"}
                    />
                  }
                />
                <NavItem
                  path="/howitworks"
                  content={
                    <IconBuilder
                      icon={<CiPaperplane size={20} />}
                      icon_text={"How it works"}
                    />
                  }
                />
                <NavItem
                  path="/signin"
                  content={
                    <IconBuilder
                      icon={<AiOutlineLogin size={20} />}
                      icon_text={"Sign in"}
                    />
                  }
                />
                <NavItem
                  path="/signup"
                  content={
                    <IconBuilder
                      icon={<BsPerson size={20} />}
                      icon_text={"Sign up"}
                    />
                  }
                />
              </>
            )}
          </MobileMenuActions>
        </MobileMenu>
        <MenuContainer isOpen={!isMobileMenuOpen}>
          {
            <NavBtnLink
              to="/howitworks"
              style={{
                color: "#00a87e",
                backgroundColor: "#fff",
                border: "1.5px solid #00a87e",
                borderRadius: "5px",
                fontSize: "1rem",
                width: "100%",
                margin: "10px 7px",
                padding: "7px 7px",
                fontWeight: "500",
                textShadow: "0px 0px 1px rgba(0, 0, 0, 0.3)",
              }}
            >
              How it works
            </NavBtnLink>
          }
          {props.user.token && (
            <NavBtnLink
              to="/swap"
              style={{
                color: "#fff",
                backgroundColor: "#00a87e",
                borderRadius: "5px",
                fontSize: "1rem",
                width: "100%",
                margin: "10px 7px",
                padding: "7px 7px",
                fontWeight: "500",
                textShadow: "0px 0px 1px rgba(0, 0, 0, 0.3)",
              }}
            >
              List your item
            </NavBtnLink>
          )}
          {!props.user.token && (
            <NavBtn>
              <NavBtnLinkNoBg to="/signin">Log in</NavBtnLinkNoBg>
              <NavBtnLinkNoBg to="/signup">Sign up</NavBtnLinkNoBg>
            </NavBtn>
          )}
          {props.user.token && (
            <NavItem
              path="/favourites"
              content={
                <IconBuilder
                  icon={<CiHeart size={28} />}
                  // icon_text={"Saved"}
                />
              }
            />
          )}
          {props.user.token && (
            <NavItem
              path="/inbox"
              content={<IconBuilder icon={<CiChat2 size={28} />} />}
              count={props.state.user.unread_message_count}
            />
          )}

          {props.user.token && (
            <NavItem2
              path="#"
              content={
                <RoundedImage
                  imageHeight={40}
                  imageWidth={40}
                  borderRadiusPct={50}
                  image={props.user?.avatar ? props.user.avatar : unknown_gw}
                />
              }
              onClick={toggleDropdown}
            >
              <Dropdown
                user={{ ...props.user }}
                isDropdownVisible={isDropdownVisible}
              />
            </NavItem2>
          )}
        </MenuContainer>
        {props.children}
      </NavContainer>
    </NavWrapper>
  );
};

const Dropdown = (props) => {
  const DropdownItem = (props) => {
    return (
      <DropdownItemMain href={props.path}>
        <DropdownItemIcon>{props.icon}</DropdownItemIcon>
        {props.children}
      </DropdownItemMain>
    );
  };
  return (
    <DropdownMain id="dropdown" visible={props.isDropdownVisible}>
      <DropdownItem
        path={"/account"}
        icon={
          <RoundedImage
            imageHeight={40}
            imageWidth={40}
            borderRadiusPct={50}
            image={props.user?.avatar ? props.user.avatar : unknown_gw}
          />
        }
      >
        <div>
          <span style={{ display: "block", fontWeight: 600 }}>
            {props.user.first_name} {props.user.last_name}
          </span>
          <span style={{ display: "block", fontSize: "12px" }}>
            View your profile
          </span>
        </div>
      </DropdownItem>
      <DropdownItem path={"/account"} icon={<AiOutlineSetting size={20} />}>
        Account Settings
      </DropdownItem>
      <DropdownItem path={"/listings"} icon={<CiShoppingTag size={25} />}>
        My Listings
      </DropdownItem>
      <DropdownItem path={"/signout"} icon={<CiLogout size={25} />}>
        Sign Out
      </DropdownItem>
    </DropdownMain>
  );
};

const NavItem2 = (props) => {
  const handleClick = (event) => {
    props.onClick();
    event.stopPropagation();
  };

  return (
    <>
      <NavLink to={props.path} onClick={handleClick}>
        {props.content}
      </NavLink>

      {props.children}
    </>
  );
};

const NavItem = (props) => {
  const navigate = useNavigate();

  const handleClick = (event) => {
    event.preventDefault(); // Prevent default action if any
    props.onClick?.(); // Safely invoke onClick if provided
    navigate(props.path); // Programmatically navigate to the path
  };

  return (
    <>
      <StyledNavItem onClick={handleClick}>
        {props.content}
        {props.count > 0 && (
          <NotificationBubble>{props.count}</NotificationBubble>
        )}
      </StyledNavItem>

      {props.children}
    </>
  );
};

const IconBuilder = (props) => {
  return (
    <IconMain>
      <span>{props.icon}</span>
      <span>{props.icon_text}</span>
    </IconMain>
  );
};

const LogoBuilder = (props) => {
  return <LogoMain>{props.logotext}</LogoMain>;
};

const SortAndFilterModal = ({
  isOpen,
  onClose,
  zip,
  handleZipCode,
  distance,
  handleDistance,
  handleFilterApply,
  handleSort,
  sortOption,
  errorMessage,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <span
        style={{
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          fontSize: "18px",
        }}
      >
        Sort & Filter
      </span>
      <ModalBody>
        <div>
          <Label>Zip Code</Label>
          <ModalInput
            type="number"
            placeholder="Enter Zip Code"
            value={zip}
            onChange={handleZipCode}
          />
        </div>
        <div>
          <Label>Radius</Label>
          <DropDwn
            value={distance}
            onChange={handleDistance}
            options={[1, 2, 5, 10, 20, 40, 60, 80, 100]}
            optionSuffix="mi"
          />
        </div>
        <SortOptions onSortChange={handleSort} sortOption={sortOption} />
        <Button
          style={{ margin: "20px 0px" }}
          onClick={handleFilterApply}
          disabled={isLoading}
          sortOption
        >
          {isLoading ? (
            <BallBeat color={colors.primary} loading={true} />
          ) : (
            "Apply"
          )}
        </Button>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </ModalBody>
    </Modal>
  );
};

const SortOptions = ({ onSortChange, sortOption }) => {
  // const [selectedSort, setSelectedSort] = useState("");

  // const handleSortChange = (option) => {
  //   setSelectedSort(option);
  //   onSortChange(option); // Propagate the selected sort option up
  // };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        display: "none",
      }}
    >
      <Label>Sort By</Label>
      <div
        style={{
          // marginBottom: "20px",
          display: "flex",
          width: "100%",
          justifyContent: "flex-start",
          gap: "20px",
        }}
      >
        <Button
          onClick={() => onSortChange("mostRecent")}
          style={{
            fontSize: "0.9rem",
            padding: "5px",
            border: "none",
            backgroundColor:
              sortOption === "mostRecent" ? "#00a87e" : "#f0f0f0",
            color: sortOption === "mostRecent" ? "#ffffff" : "#000000",
            // Additional button styling here
          }}
        >
          Most Recent
        </Button>
        <Button
          onClick={() => onSortChange("mostRelevant")}
          style={{
            fontSize: "0.9rem",
            padding: "5px",
            border: "none",
            backgroundColor:
              sortOption === "mostRelevant" ? "#00a87e" : "#f0f0f0",
            color: sortOption === "mostRelevant" ? "#ffffff" : "#000000",
            // Additional button styling here
          }}
        >
          Most Relevant
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      search,
      zipcode,
      location,
      distance,
      categories,
      sortBy,
      itemConditions,
      zipcodeDistChanged,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
