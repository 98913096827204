import React from "react";
import styled from "styled-components";
import { Label } from "../styles/Shared";

const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`;

const DropdownSelect = styled.select`
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #666;
  font-size: 14px;
  background-color: white; /* add this line to set the background color to white */

  &::placeholder {
    // color: red;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f0f0f0;
  }
`;

const DropdownOption = styled.option``;

const DropDwn = (props) => {
  const { value, onChange, options, optionSuffix, optionLabel } = props;

  const handleChange = (event) => {
    onChange(parseInt(event.target.value));
  };

  return (
    <DropdownContainer>
      <Label htmlFor={optionLabel + "-select"}>{optionLabel}</Label>
      <DropdownSelect
        id={optionLabel + "-select"}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <DropdownOption key={option} value={option}>
            {option} {optionSuffix}
          </DropdownOption>
        ))}
      </DropdownSelect>
    </DropdownContainer>
  );
};

export default DropDwn;
