import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/auth";
import searchReducer from "./reducers/search";
import { loadState, saveState } from "../sessionStorage";

const rootReducer = combineReducers({
  user: authReducer,
  search: searchReducer,
});

const persistedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
});

//data from redux store is uploaded to local storage. This is done every time storage is changed
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
