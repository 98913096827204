// const BASE_API = "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod"

export const API_CONSTANT_MAP = {
  signup: "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/signup",
  signin: "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/signin",
  search: "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/search",
  create_listing:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/create_listing",
  get_listings:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_listings",
  get_listing_by_id:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_listing_by_id",
  create_conversation:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/create_conversation",
  get_conversations:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_conversations",
  get_user_listings:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_user_listings",
  create_conversation_swap:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/create_conversation_swap",
  get_conversation_by_id:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_conversation_by_id",
  update_conversation_swap:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_conversation_swap",
  get_messenger_messages:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_messenger_messages",
  create_listing_image:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/create_listing_image",
  create_favourite_listing:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/create_favourite_listing",
  get_favourite_listing:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_favourite_listing",
  get_account_details:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_account_details",
  update_account_details:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_account_details",
  get_avatar_presigned_url:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_avatar_presigned_url",
  get_listing_conversation:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_listing_conversation",
  get_swaps:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_swap",
  get_user_favourites:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_user_favourites",
  get_messages:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_messages",
  get_conversation_swaps:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_conversation_swaps",
  update_conversation_swaps:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_conversation_swaps",
  get_categories:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_categories",
  get_categories_v2:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_categories_v2",
  get_your_listings:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_your_listings",
  delete_listing:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/delete_listing",
  get_listing_for_edit:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_listing_for_edit",
  update_listing:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_listing",
  get_item_conditions:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_item_conditions",
  get_listing_by_conv_id:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_listing_by_conv_id",
  forgot_password:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/forgot_password",
  reset_password:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/reset_password",

  verify_email:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/verify_email",

  update_account_password:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_account_password",
  get_zipcode_listings:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/get_zipcode_listings",
  validate_zipcode:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/validate_zipcode",
  search_postgres:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/search_postgres",
  update_conversation_read:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_conversation_read",
  update_unread_count:
    "https://k36dlnf1uf.execute-api.us-west-2.amazonaws.com/prod/update_unread_count",
};
