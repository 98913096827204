import React, { useState, useEffect, useRef } from "react";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import Loader from "../components/Loading";
import { request } from "../services/networking/request";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { FormListing, FormListingSkeleton } from "../components/ListingForm";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { uploadToS3 } from "../utils/helpers/s3";
import Modal from "../components/Modal";
import styled from "styled-components";

const ErrorMessage = styled.p`
  color: red;
  font-weight: bold;
  background-color: #ffd2d2;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const CancelButton = styled.button`
  margin-left: 10px;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  background-color: #ccc;
  color: #333;
  border-radius: 4px;
`;

const EditListing = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const listingId = searchParams.get("listing_id");
  const [isLoading, setIsLoading] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [listingsLoaded, setListingsLoaded] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    zipcode: "",
    images: [],
    category_id: "",
    subcategory_id: "",
    listing_id: "",
  });

  const [imagePreviews, setImagePreviews] = useState([]);
  const [presignedUrls, setPresignedUrls] = useState([]);
  const [catData, setcatData] = useState({});
  const [categories, setCategories] = useState([]);
  const [itemConditionData, setItemConditionData] = useState({});

  const [subcategories, setSubcategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [itemConditions, setItemConditions] = useState([]);

  const [selectedItemCondition, setSelectedItemCondition] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const textAreaRef = useRef(null);

  function adjustTextAreaHeight() {
    const textArea = textAreaRef.current;
    textArea.style.height = "auto";
    textArea.style.height = textArea.scrollHeight + "px";
  }

  const handleFormFieldChange = (event) => {
    const fieldName = event.target.id;
    let fieldValue = event.target.value;

    if (event.target.type === "file") {
      const newImages = Array.from(event.target.files);
      setFormData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...newImages],
      }));

      const previews = newImages.map((file) => URL.createObjectURL(file));
      setImagePreviews((prevImagePreviews) => [
        ...prevImagePreviews,
        ...previews,
      ]);
    } else {
      setFormData({ ...formData, [fieldName]: fieldValue });
    }
  };

  const handleRemoveImage = (index) => {
    const newImages = [...formData.images];
    newImages.splice(index, 1);
    setFormData({ ...formData, images: newImages });

    const newPreviews = [...imagePreviews];
    newPreviews.splice(index, 1);
    setImagePreviews(newPreviews);

    console.log(0);
  };

  const handleSubmit = async (event) => {
    console.log("In submit");
    setIsLoading(true);
    event.preventDefault();
    const errors = {};
    const { images, ...formDataWithoutImages } = formData;
    // Filter out the new images directly from formData.images
    const newImages = formData.images
      .filter((image) => image && typeof image !== "string") // Filter out only the new images
      .map((image) => image.name); // Extract the name of the image

    // Filter out the existing images directly from formData.images
    const existingImages = formData.images.filter(
      (image) => typeof image === "string"
    );

    const formDataWithImages = {
      ...formDataWithoutImages,
      new_images: newImages,
      existing_images: existingImages,
    };
    const formDataJSON = JSON.stringify(formDataWithImages);
    console.log(formDataJSON);
    if (Object.keys(errors).length === 0) {
      const endpoint = API_CONSTANT_MAP.update_listing;
      try {
        const response = await request("POST", endpoint, formDataJSON);
        console.log(response);
        if (response.data.success) {
          setPresignedUrls(response.data.presigned_urls);
          setTrigger(true);
        } else {
          setIsLoading(false);
          setErrorModal(true);
        }
      } catch (err) {
        setIsLoading(false);
        setErrorModal(true);
        setErrorMessage(err.error.message);
      }
    }

    setIsLoading(false);
  };

  useEffect(() => {
    const textArea = textAreaRef.current;
    textArea.addEventListener("input", adjustTextAreaHeight);
    return () => {
      textArea.removeEventListener("input", adjustTextAreaHeight);
    };
  }, []);

  useEffect(() => {
    const uploadImagesToS3 = async () => {
      if (presignedUrls.length > 0 && listingId) {
        try {
          const newImages = formData.images.filter(
            (image) => image && typeof image !== "string"
          );
          console.log(newImages);
          console.log(presignedUrls);

          const results = await Promise.allSettled(
            presignedUrls.map((url, index) => uploadToS3(url, newImages[index]))
          );
          const rejects = results.filter((x) => x.status !== "fulfilled");
          if (rejects.length === 0) {
            console.log("All fulfilled");
            const image_paths = presignedUrls.map(
              (e) => e.url + e.fields["key"]
            );
            const endpoint = API_CONSTANT_MAP.create_listing_image;
            const response = await request("POST", endpoint, {
              listing_id: listingId,
              image_urls: image_paths,
            });
            console.log(response);
            // Handle the response
            if (response.data.success) {
              // Navigate to a different page
              navigate(`/listings`);
            } else {
              // Alert the user of an error
              alert(
                "Error: " + (response.data.message || "Something went wrong!")
              );
            }
          }
        } catch (error) {
          console.error(error);
          // It's a good idea to also alert the user in case of an exception
          alert("Error: " + (error.message || "An unexpected error occurred."));
        }
      } else {
        navigate(`/listings`);
      }
    };
    if (trigger == true) {
      uploadImagesToS3();
      setTrigger(false);
    }
  }, [trigger]); // Added navigate as a dependency

  useEffect(() => {
    const result = request("POST", API_CONSTANT_MAP.get_listing_for_edit, {
      listing_id: listingId,
    });
    result
      .then((result) => {
        console.log(result);
        if (result.data.success) {
          setFormData(result.data.listing);
          setImagePreviews(result.data.listing.images);
          setSelectedCategory(result.data.listing.category);
          setSelectedItemCondition(result.data.listing.item_condition);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const result = request("POST", API_CONSTANT_MAP.get_categories);
    result
      .then((result) => {
        if (result.data.success) {
          if (Object.keys(catData).length === 0) {
            // catData is an empty object
            setListingsLoaded(true);
          }

          setcatData(result.data.categories);
          setCategories(Object.keys(result.data.categories));
          const categoryData = result.data.categories[selectedCategory];
          if (categoryData) {
            setSubcategories(categoryData.subcategories);
            const selectedSubcategory = categoryData.subcategories.find(
              (obj) => obj.id === formData.subcategory_id
            );
            if (selectedSubcategory) {
              setSelectedSubcategory(selectedSubcategory);
            }
          }
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [selectedCategory]);

  useEffect(() => {
    const result = request("POST", API_CONSTANT_MAP.get_item_conditions);
    result
      .then((result) => {
        if (result.data.success) {
          console.log(result);
          setItemConditionData(result.data.item_conditions);
          setItemConditions(Object.keys(result.data.item_conditions));
        }
      })
      .catch(() => {});
  }, []);

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setSelectedCategory(value);
    setSelectedSubcategory("");
    if (!categories.includes(value)) {
      setSubcategories([]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        category_id: null,
      }));
    } else {
      const categoryData = catData[value];
      setSubcategories(categoryData["subcategories"]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        category_id: categoryData["id"],
      }));
    }
  };

  const handleSubcategoryChange = (event) => {
    const parsed = JSON.parse(event.target.value);

    setSelectedSubcategory(parsed);
    setFormData((prevFormData) => ({
      ...prevFormData,
      subcategory_id: parsed.id,
    }));
  };

  const handleItemConditionChange = (event) => {
    const value = event.target.value;
    const itemCondition = itemConditionData[value];
    setSelectedItemCondition(value);
    setFormData((prevFormData) => ({
      ...prevFormData,
      item_condition_id: itemCondition["id"],
    }));
  };

  const handleErrorModal = (e) => {
    setErrorModal(!errorModal);
  };

  const handleCancel = () => {
    // Navigate to the "My Listings" page
    navigate("/listings");
  };

  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <FormListing
          formData={formData}
          imagePreviews={imagePreviews}
          handleFormFieldChange={handleFormFieldChange}
          handleRemoveImage={handleRemoveImage}
          handleSubmit={handleSubmit}
          handleCategoryChange={handleCategoryChange}
          handleSubcategoryChange={handleSubcategoryChange}
          handleItemConditionChange={handleItemConditionChange}
          categories={categories}
          subcategories={subcategories}
          itemconditions={itemConditions}
          selectedCategory={selectedCategory}
          selectedSubcategory={selectedSubcategory}
          selectedItemCondition={selectedItemCondition}
          textAreaRef={textAreaRef}
          cancelPath="/listings"
        />

        {/* <CancelButton onClick={handleCancel}>Cancel</CancelButton> */}

        {/* {!listingsLoaded && <FormListingSkeleton />} */}
      </OuterContainer>
      <Loader animating={isLoading} />
      <Modal isOpen={errorModal} onClose={handleErrorModal}>
        <ErrorMessage>{errorMessage}</ErrorMessage>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(EditListing);
