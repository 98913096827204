import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import NavBar from "../components/layout/NavBar";
import Loader from "../components/Loading";

const StyledOuterContainer = styled(OuterContainer)`
  min-height: 300px;
  max-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const EmailConfirmation = (props) => {
  const location = useLocation();
  const [token, setToken] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    if (token) {
      setToken(token);
    }
  }, [location]);

  useEffect(() => {
    if (token) {
      setIsLoading(true);
      const endpoint = API_CONSTANT_MAP.verify_email;
      const result = request("POST", endpoint, { token: token });

      result
        .then((result) => {
          if (result.data.success) {
            setMessage(result.data.message);
          } else {
            setError(result.data.error.message);
          }
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [token]);

  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <StyledOuterContainer>
          {message && <p>{message}</p>}
          {error && <p>{error}</p>}
        </StyledOuterContainer>
      </OuterContainer>
      <Loader animating={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(EmailConfirmation);
