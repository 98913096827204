import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  & > input {
    border: 1px solid #dadce0;
    border-radius: 5px;
    padding: 7px 5px 7px 10px;
    outline: none;
    font-size: 12px;
    margin-top: 3px;
  }

  & > label {
    font-size: 12px;
    font-weight: 500;
    text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
  }
`;

const TextInput = (props) => {
  const defaultProps = {
    noChange: false,
    placeholder: "",
    label: "",
  };

  const {
    id,
    name,
    value,
    type,
    // classes,
    onChange,
    placeholder,
    label,
    style,
  } = {
    ...defaultProps,
    ...props,
  };

  const handleChange = (event) => {
    onChange(event);
  };

  // const { contClass, fieldClass, errorClass } = classes;
  // className={contClass}
  return (
    <Container>
      <label htmlFor={name}>{label}</label>
      <input
        type="text"
        id={id}
        name={name}
        type={type}
        // class={fieldClass}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        style={style}
      />
      {/* <p className={errorClass}></p> */}
    </Container>
  );
};

export default TextInput;
