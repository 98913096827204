// Dependencies
import React from "react";
import styled from "styled-components";
// Helpers
import { BallBeat } from "react-pure-loaders";
// Constants
import colors from "../utils/constants/colors";

const SpinnerOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 100000;
`;

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Loader = (props) => {
  return props.animating ? (
    <SpinnerOverlay>
      <SpinnerContainer>
        <BallBeat color={colors.primary} loading={true} />
      </SpinnerContainer>
    </SpinnerOverlay>
  ) : null;
};

export default Loader;
