import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
// index.css overwrites bootstrap { Always keep it at last}
import "./index.css";
import App from "./App";
import store from "./store/configStore";
import LogRocket from "logrocket";
LogRocket.init("b58p1o/swapthings");

{
  /* <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/latest/css/bootstrap.min.css"></link> */
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <>
      <App />
    </>
  </Provider>
);
