import { authHeader } from "../../utils/helpers/api";

const axios = require("axios").default;

export const request = async (method, url, data = null) => {
  const authHead = authHeader();

  try {
    const config = {
      method: method,
      url: url,
      // `${BASE_URL}/${url}`,
      data: data,
      headers: {
        "Content-Type": "application/json",
        ...authHead,
      },
    };
    const response = await axios(config);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};
