import React, { useState, useEffect } from "react";
import NavBar from "../components/layout/NavBar";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import { daysBetween } from "../utils/helpers/date";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import OuterContainer from "../components/layout/OuterContainer";
import RoundedImage from "../components/RoundedImage";
import unknown_gw from "../assets/unknown_gw.png";
import { size } from "../utils/constants/css";
import Button from "../components/common/Button";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// import InboxSkeleton from "../components/skeletons/InboxSkeleton";

const InboxMain = styled.ul`
  padding: 0px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  width: 100%;
  margin: auto;

  @media only screen and (min-width: ${size.mobileM}px) {
    max-width: 500px;
  }
`;

const InboxItemMain = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 150px;
  transition: background 100ms;
  font-size: 14px;
  border-bottom: 1px solid #e0e0e0;
  background-color: ${(props) =>
    props.unread ? "#cfcfcf" : "white"}; /* Darker background for unread */

  &:hover {
    background-color: #f6f6f6;
  }
`;

const InboxMessage = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  max-height: 100%;
`;

const InboxItemIcon = styled.div`
  padding: 0.5rem;
`;

const InboxMessageContent = styled.div`
  margin: 5px 10px;
  flex: 1;
  max-height: 100%;
  overflow: hidden;

  & > .two-lines-ellipsis {
    margin: 5px 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines you want */
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    white-space: normal; /* Override the default of 'nowrap' */
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoConvsContainer = styled.div`
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Inbox = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [convsLoaded, setConvsLoaded] = useState(false);

  const current_user_id = props.state.user.user_id;

  useEffect(() => {
    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.get_conversations;
    const result = request("POST", endpoint, { data: false });
    result
      .then((result) => {
        if (result.data.success) {
          console.log(result);
          setConversations(result.data.conversations);
        }
        setIsLoading(false);
        setConvsLoaded(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClick = (id) => {
    navigate(`/inbox/message/${id}`);
  };

  const handleHomeClick = (id) => {
    navigate(`/`);
  };

  useEffect(() => {
    const endpoint = API_CONSTANT_MAP.update_unread_count;
    const result = request("POST", endpoint, { notification_type_id: 1 });

    result
      .then((response) => {
        if (response.data.success) {
          //  setIsLoaded(true); // Set the loaded state to true once the data is fetched
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          //  setErrorMsg(error.response.data.error.message);
        }
      });
  }, []);

  console.log(conversations);

  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Main>
          {isLoading && (
            <InboxContainer>
              <InboxSkeleton cards={8} />
            </InboxContainer>
          )}
          {convsLoaded && conversations.length == 0 && (
            <NoConvsContainer>
              <h1 style={{ fontWeight: "500" }}>You have no messages</h1>
              <p>
                When people contact you about your items on <br />
                Swapthings, you'll see their messages here.
              </p>
              <Button
                type="submit"
                style={{
                  margin: "20px 0px",
                  backgroundColor: "#00a87e",
                  border: "none",
                }}
                onClick={handleHomeClick}
              >
                Explore Swap
              </Button>
            </NoConvsContainer>
          )}
          {convsLoaded && conversations.length > 0 && (
            <InboxContainer>
              {conversations.map((item, index) => (
                <InboxItem
                  icon={
                    <RoundedImage
                      imageHeight={70}
                      imageWidth={70}
                      image={
                        item?.sender?.avatar ? item.sender.avatar : unknown_gw
                      }
                    />
                  }
                  conversation={{ ...item, current_user_id: current_user_id }}
                  key={index}
                  handleClick={handleClick}
                  listing_image={
                    <RoundedImage
                      imageHeight={70}
                      imageWidth={70}
                      borderRadiusPct={5}
                      image={item.listing_image_url}
                    />
                  }
                  unread={item.unread}
                />
              ))}
            </InboxContainer>
          )}
        </Main>
      </OuterContainer>
    </>
  );
};

const InboxContainer = (props) => {
  return <InboxMain>{props.children}</InboxMain>;
};

const InboxItem = (props) => {
  const { icon, handleClick, conversation, listing_image, unread } = props;
  return (
    <InboxItemMain onClick={() => handleClick(conversation.id)} unread={unread}>
      <InboxMessage>
        <InboxItemIcon>{icon}</InboxItemIcon>
        <InboxMessageContent>
          <div style={{ fontWeight: 600 }}>
            {conversation.sender.first_name} {conversation.sender.last_name}
          </div>
          <p className="two-lines-ellipsis">
            {conversation.last_message_sender_id ===
            conversation.current_user_id
              ? "You: "
              : ""}
            {conversation.last_message}
          </p>
          <span>{daysBetween(conversation.last_message_timestamp)}</span>
        </InboxMessageContent>
        <InboxItemIcon>{listing_image}</InboxItemIcon>
      </InboxMessage>
    </InboxItemMain>
  );
};

const InboxSkeleton = ({ cards }) => {
  return Array(cards)
    .fill(0)
    .map((_, i) => (
      <InboxItemMain key={i}>
        <InboxMessage>
          <InboxItemIcon>
            <Skeleton circle width={50} height={50} />
          </InboxItemIcon>
          <div style={{ flex: 1 }}>
            <Skeleton count={3} style={{ marginBottom: ".6rem" }} />
          </div>
          <InboxItemIcon>
            <Skeleton width={50} height={50} />
          </InboxItemIcon>
        </InboxMessage>
      </InboxItemMain>
    ));
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Inbox);
