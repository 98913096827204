import React, { useState } from "react";
import { SlArrowLeft, SlArrowRight } from "react-icons/sl";
import styled from "styled-components";
import { size } from "../utils/constants/css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Slider = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  column-gap: 15px;

  @media screen and (max-width: ${size.tablet}px) {
    flex-direction: column;
    justify-content: start;
    row-gap: 15px;
  }
`;

const slideStyles = {
  borderRadius: "3px",
  height: "100%",
  // backgroundSize: "cover",
  // backgroundPosition: "center",
};

const SliderPreview = styled.div`
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow-y: scroll;
  display: flex;
  width: 100px;
  height: 100%;
  gap: 7px;
  padding: 0px;

  .preview {
    width: 100%;
    height: 100px;
    border-radius: 1rem;
    cursor: pointer;
    object-fit: cover;
    object-position: top;
    box-sizing: border-box;

    @media screen and (max-width: ${size.tablet}px) {
      border-radius: 0.5rem;
      height: 100%;
      width: 75px;
    }
  }

  @media screen and (max-width: ${size.tablet}px) {
    display: none;
    flex-direction: row;
    overflow: scroll;
    width: 100%;
    height: 75px;
    & > * {
      flex-shrink: 0;
    }

    & > div {
      margin-bottom: 0px;
    }
  }
`;

const Image = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > img {
    border-radius: 1rem;
    object-fit: cover;
    object-position: top;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;

    @media screen and (max-width: ${size.tablet}px) {
      border-radius: 1rem;
    }
  }

  @media (max-width: 768px) {
    order: -1;
  }

  @media screen and (max-width: ${size.tablet}px) {
    width: 100%;
    height: 600px;
  }
`;

const LeftButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  left: 5px;
  z-index: 10;
  background-color: #fff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

const RightButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  position: absolute;
  top: 50%;
  right: 5px;
  z-index: 10;
  background-color: #fff;
  opacity: 0.9;
  display: flex;
  align-items: center;
  justify-content: center;

  &:focus {
    outline: none;
    box-shadow: none;
  }
`;

export const ImageSlider = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const length = slides.length;

  const nextSlide = () => {
    setCurrentIndex(currentIndex === length - 1 ? 0 : currentIndex + 1);
  };

  const prevSlide = () => {
    setCurrentIndex(currentIndex === 0 ? length - 1 : currentIndex - 1);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }

  const slideStylesWidthBackground = {
    // ...slideStyles,
    backgroundImage: `url(${slides[currentIndex].image})`,
  };

  return (
    <Slider>
      <SliderPreview>
        {slides.map((slide, index) => (
          <img
            key={index}
            className="preview"
            style={{
              backgroundImage: `url(${slide.image})`,
              opacity: index === currentIndex ? "1" : "0.8",
              border: index === currentIndex ? "4px solid #00a87e" : "none",
            }}
            src={slide.image}
            active={index == currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </SliderPreview>
      <Image>
        {slides.length > 1 && (
          <>
            <LeftButton onClick={prevSlide}>
              <SlArrowLeft size={20} />
            </LeftButton>
            <RightButton onClick={nextSlide}>
              <SlArrowRight size={20} />
            </RightButton>
          </>
        )}

        <img src={slides[currentIndex].image} />
      </Image>
    </Slider>
  );
};

export const SkeletonImageSlider = () => {
  return (
    <Slider>
      <SliderPreview>
        {Array(2)
          .fill(0)
          .map((_, index) => (
            <Skeleton key={index} className="preview" width={90} height={90} />
          ))}
      </SliderPreview>
      <Image>
        <Skeleton style={{ ...slideStyles }} />
      </Image>
    </Slider>
  );
};
