// Dependencies
import React from "react";
import styled from "styled-components";
// Constants
import { randomcolor } from "../utils/helpers/css";

// https://contactmentor.com/how-to-add-loading-spinner-react-js/
const Image = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => (props.imageWidth ? props.imageWidth : 50)}px;
  height: ${(props) => (props.imageHeight ? props.imageHeight : 50)}px;
  border-radius: ${(props) =>
    props.borderRadiusPct ? props.borderRadiusPct : 50}%;
  background: url(${(props) => props.image}) no-repeat center center;
  background-size: cover;
  overflow: hidden;
  position: relative;
`;

const RoundedImage = (props) => {
  return <Image {...props} />;
};

export default RoundedImage;
