import React from "react";
import styled from "styled-components";

const Container = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const Button = (props) => {
  const defaultProps = {
    border: "1px solid #000",
    radius: "5px",
    onClick: () => {},
    width: "100%",
    padding: "10px",
    backgroundColor: "#000",
    color: "#fff",
    disabled: false,
    fontSize: "1rem",
  };

  const {
    border,
    radius,
    onClick,
    width,
    padding,
    backgroundColor,
    color,
    type,
    style,
    disabled,
    className,
    fontSize,
  } = {
    ...defaultProps,
    ...props,
  };

  const handleClick = (e) => {
    if (!disabled) {
      onClick(e);
    }
  };

  return (
    <Container>
      <button
        type="submit"
        onClick={handleClick}
        className={className}
        style={{
          ...{
            backgroundColor: backgroundColor,
            border,
            borderRadius: radius,
            padding,
            width,
            color,
            type,
            fontSize,
            cursor: disabled ? "not-allowed" : "pointer",
            opacity: disabled ? 0.6 : 1,
          },
          ...style,
        }}
        disabled={disabled}
      >
        {props.children}
      </button>
    </Container>
  );
};

export default Button;
