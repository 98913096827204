import React from 'react';
import styled from 'styled-components';


const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
    background: none;
    color: #00a87e;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 13px;
    font-weight: 600;
`;


const FileUploader = (props) => {
    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
    };

    return (
    <>
        <Button onClick={handleClick}>
            {props.buttontext}
        </Button>
        <input type="file" 
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display:'none'}} 
        /> 
    </>
    );
};
export default FileUploader;