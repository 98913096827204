import React, { useState, useEffect } from "react";
import { request } from "../services/networking/request";
import styled from "styled-components";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import { size } from "../utils/constants/css";
import { useNavigate } from "react-router-dom";
import { Listing, ListingSkeleton } from "../components/Listing";
import OuterContainer from "../components/layout/OuterContainer";
import Button from "../components/common/Button";
import Meta from "../components/Meta";

const ListingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  column-gap: 35px;
  row-gap: 100px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${size.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 20px;
    row-gap: 70px;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoListingsContainer = styled.div`
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Favorites = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [favorites, setFavorites] = useState([]);
  const [favsLoaded, setFavsLoaded] = useState(false);

  const openListing = (id) => {
    navigate(`/listing/details/${id}`);
  };

  const unSaveFavourite = (event, listing_id) => {
    event.stopPropagation();

    const endpoint = API_CONSTANT_MAP.create_favourite_listing;
    const result = request("POST", endpoint, { listing_id: listing_id });
    result
      .then((result) => {
        if (result.data.success) {
          const newFavorites = favorites.filter(
            (favorite) => favorite.listing_id !== listing_id
          );
          // Update the favorites state with the new array
          setFavorites(newFavorites);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.get_user_favourites;
    const result = request("POST", endpoint);
    result
      .then((result) => {
        setFavorites(result.data.favorites);
        setIsLoading(false);
        setFavsLoaded(true);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleClick = () => {
    navigate("/");
  };
  return (
    <Meta
      pageMeta={{
        title: "Favourites - Swapthings",
      }}
    >
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Main>
          {favsLoaded && favorites.length > 0 && (
            <ListingContainer>
              {favorites.map((favorite) => (
                <Listing
                  id={favorite.listing_id}
                  title={favorite.title}
                  imageUrl={favorite.image_url}
                  description={favorite.description}
                  onClick={openListing}
                  onRemove={unSaveFavourite}
                />
              ))}
            </ListingContainer>
          )}

          {isLoading && (
            <ListingContainer>
              {Array(6)
                .fill(0)
                .map((_, i) => (
                  <ListingSkeleton id={i} title={true} />
                ))}
            </ListingContainer>
          )}

          {favsLoaded && favorites.length == 0 && (
            <NoListingsContainer>
              <h1 style={{ fontWeight: "500" }}>No Saved Items</h1>
              <span>When you favorite an item, you’ll see it here.</span>
              <Button
                type="submit"
                style={{
                  margin: "20px 0px",
                  backgroundColor: "#00a87e",
                  border: "none",
                }}
                onClick={handleClick}
              >
                Find an Item
              </Button>
            </NoListingsContainer>
          )}
        </Main>
      </OuterContainer>
    </Meta>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Favorites);
