import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import TextField from '../components/forms/TextField'
import { validateField } from "../utils/helpers/validate";
import NavBar from "../components/layout/NavBar";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import { signin } from "../store/actions";
import TextInput from "../components/common/TextInput";
import Button from "../components/common/Button";
import Loader from "../components/Loading";
import OuterContainer from "../components/layout/OuterContainer";
import styled from "styled-components";
import Meta from "../components/Meta";

const SignInContainer = styled.div`
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: block;
  flex-shrink: 0;
  margin: 0 auto;
  min-height: 0;
  width: 450px;
  max-width: 98%;
  padding: 48px 40px 36px;
`;

const Logo = styled.div`
  font-size: 18px;
  color: #00a87e;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  margin: 10px 0px 30px 0px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;

const Header = styled.h1`
  padding: 10px 0px;
  color: #202124;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 0px 0px;
  font-size: 0.9rem;

  & > span {
    padding: 5px;
  }

  & .link {
    color: #00a87e;
    cursor: pointer;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin: 3px 0px 10px 0px;
`;

const SignIn = (props) => {
  const navigate = useNavigate();
  const initialState = { email: "", password: "" };
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
    // Update form errors as you type
    const errors = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors ? errors : null,
    }));
  };

  useEffect(() => {
    if (isSubmit) {
      props.signin({ type: "SIGNIN_INIT" });

      const endpoint = API_CONSTANT_MAP.signin;
      const result = request("POST", endpoint, formValues);

      result
        .then((result) => {
          if ("token" in result.data) {
            props.signin({
              type: "SIGNIN_SUCCESS",
              payload: result.data,
            });
            navigate("/");
          }
        })
        .catch((response) => {
          if (response.error) {
            // User already exists
            setErrorMsg(response.error.message);
          }
          props.signin({
            type: "SIGNIN_FAILURE",
          });
        });
    }
  }, [isSubmit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    console.log(errors);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmit(!isSubmit);
    }
  };

  const handleCreateAccount = (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    navigate("/password/reset/start");
  };

  const validate = (values) => {
    console.log(-1);
    const errors = {};
    const email = validateField("email", values.email);
    const password = validateField("password", values.password);
    console.log(email);
    if (email) {
      errors.email = email;
    }
    if (password) {
      errors.password = password;
    }

    return errors;
  };

  console.log(formErrors);
  return (
    <Meta
      pageMeta={{
        title: "Sign in - Swapthings",
      }}
    >
      <NavBar user={props.state.user} />
      <OuterContainer>
        <SignInContainer>
          <Header>
            <span>Sign in</span>
          </Header>
          <Logo>
            <span>Get started on Swap</span>
          </Logo>
          <form onSubmit={handleSubmit}>
            <TextInput
              value={formValues.email}
              name={"email"}
              label={"Email address"}
              onChange={handleChange}
              placeholder={"Enter your email"}
            />
            {formErrors.email && (
              <ErrorMessage>{formErrors.email}</ErrorMessage>
            )}
            <TextInput
              value={formValues.password}
              name={"password"}
              label={"Password"}
              onChange={handleChange}
              type={"password"}
              placeholder={"Enter your password"}
            />
            {formErrors.password && (
              <ErrorMessage>{formErrors.password}</ErrorMessage>
            )}
            <Button type="submit" style={{ margin: "20px 0px" }}>
              <span>Sign in</span>
            </Button>
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            <Footer>
              <span>
                New to Swap?{" "}
                <span class="link" onClick={handleCreateAccount}>
                  Sign up
                </span>
              </span>
              <span class="link" onClick={handleForgotPassword}>
                Forgot Password?
              </span>
            </Footer>
          </form>
        </SignInContainer>
      </OuterContainer>
      <Loader animating={props.state.user.isLoading} />
    </Meta>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ signin }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
