import React, { useState } from "react";
import styled from "styled-components";
import { FaMapMarkerAlt } from "react-icons/fa";
import { size } from "../utils/constants/css"; // Adjust the import path as needed
import { BallBeat } from "react-pure-loaders";
import colors from "../utils/constants/colors";
import Modal from "./Modal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  zipcode,
  distance,
  sortBy,
  location,
  zipcodeDistChanged,
} from "../store/actions";
import DropDwn from "./DropDown";
import { Label } from "../styles/Shared";
import Button from "./common/Button";
import { request } from "../services/networking/request";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";

const StyledLocationButton = styled.div`
  align-items: center;
  cursor: pointer;
  color: #00a87e;
  font-size: 1.1rem;
  background-color: #fff;
  display: none;
  padding: 10px 10px; /* Increase padding for better appearance */

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: 8px; // Space between icon and text
  }

  @media screen and (max-width: ${size.tablet}px) {
    display: flex;
    font-size: 0.85rem;
    max-width: 140px;
  }
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const ModalInput = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  color: #666;
  transition: border-color 0.2s ease-in-out;
  width: 100%;
`;

const ErrorMessage = styled.p`
  color: red;
  font-weight: 500;
  background-color: #ffd2d2;
  font-size: 0.9rem;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
`;

const LocButton = (props) => {
  const [showFilter, setShowFilter] = useState(false);
  const [zip, setZip] = useState(props.state.search.zipcode);
  const [distance, setDistance] = useState(props.state.search.distance);
  //   const [sortOption, setSortOption] = useState(props.state.search.sortBy);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFilterToggle = () => {
    setShowFilter(!showFilter);
  };

  function handleDistance(value) {
    setDistance(value);
  }

  const handleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleZipCode = (e) => {
    setZip(e.target.value);
  };

  //   const onSortChange = (option) => {
  //     setSortOption(option);
  //   };

  const handleFilterApply = () => {
    setIsLoading(true); // Start loading
    setErrorMessage(""); // Reset any existing errors

    const result = request("POST", API_CONSTANT_MAP.validate_zipcode, {
      zipcode: zip,
    });

    result
      .then((result) => {
        console.log(result);
        setIsLoading(false); // Stop loading regardless of outcome
        if (result.data.success) {
          console.log(result.data.data.city);
          props.zipcode({
            payload: { zipcode: zip },
          });
          props.distance({
            payload: { distance: distance },
          });
          props.location({
            payload: { location: result.data.data.city },
          });

          props.zipcodeDistChanged({
            payload: {
              zipcodeDistChanged:
                props.state.search.zipcode !== zip ||
                props.state.search.distance !== distance,
            },
          });

          setShowFilter(false); // Possibly hide filter options after application
        } else {
          setErrorMessage(result.data.error.message); // Show error message from response
        }
      })
      .catch((error) => {
        setIsLoading(false); // Ensure loading is stopped in case of error
        console.error("Error applying filters:", error);
        setErrorMessage("Failed to apply filters."); // Set a generic error message
      });
  };

  return (
    <>
      <StyledLocationButton
        onClick={handleFilterToggle}
        className={props.className}
      >
        <FaMapMarkerAlt size={20} />
        <span>{`${props.state.search.location}: ${props.state.search.distance} mi`}</span>
      </StyledLocationButton>
      <SortAndFilterModal
        isOpen={showFilter}
        onClose={handleFilter}
        zip={zip}
        handleZipCode={handleZipCode}
        distance={distance}
        handleDistance={handleDistance}
        handleFilterApply={handleFilterApply}
        // handleSort={onSortChange}
        // sortOption={sortOption}
        errorMessage={errorMessage}
        isLoading={isLoading}
      />
    </>
  );
};

const SortAndFilterModal = ({
  isOpen,
  onClose,
  zip,
  handleZipCode,
  distance,
  handleDistance,
  handleFilterApply,
  handleSort,
  sortOption,
  errorMessage,
  isLoading,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <span
        style={{
          fontWeight: "700",
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          fontSize: "18px",
        }}
      >
        Sort & Filter
      </span>
      <ModalBody>
        <div>
          <Label>Zip Code</Label>
          <ModalInput
            type="number"
            placeholder="Enter Zip Code"
            value={zip}
            onChange={handleZipCode}
          />
        </div>
        <div>
          <Label>Radius</Label>
          <DropDwn
            value={distance}
            onChange={handleDistance}
            options={[1, 2, 5, 10, 20, 40, 60, 80, 100]}
            optionSuffix="mi"
          />
        </div>
        {/* <SortOptions onSortChange={handleSort} sortOption={sortOption} /> */}
        <Button
          style={{ margin: "20px 0px" }}
          onClick={handleFilterApply}
          disabled={isLoading}
          sortOption
        >
          {isLoading ? (
            <BallBeat color={colors.primary} loading={true} />
          ) : (
            "Apply"
          )}
        </Button>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { zipcode, location, distance, sortBy, zipcodeDistChanged },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LocButton);
