import React from "react";
import { Link } from "react-router-dom";
import NavBar from "../components/layout/NavBar";
import OuterContainer from "../components/layout/OuterContainer";
import { connect } from "react-redux";
import styled from "styled-components";

const NotFoundContainer = styled.div`
  text-align: center;
  margin-top: 40px;
`;

const Heading = styled.h1`
  font-size: 16px;
  font-weight: 600;
`;

const NotFoundPage = (props) => {
  return (
    <>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <NotFoundContainer>
          <Heading>Sorry, this page couldn't be found</Heading>
          <p>404 error - Not found</p>
          <p style={{ marginTop: "40px" }}>
            <Link
              to="/"
              style={{
                color: "#00a87e",
                fontWeight: "600",
                fontSize: "14px",
              }}
            >
              Back to Home
            </Link>
          </p>
        </NotFoundContainer>
      </OuterContainer>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(NotFoundPage);
