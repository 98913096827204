import styled from "styled-components";

export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SecondaryContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PageTitle = styled.h1`
  color: #111;
  font-family: Helvetica !important;
  font-size: 20px;
  line-height: 26px;
  margin: 0 auto;
  max-width: 25ch;
  padding: 32px 0 26px;
  text-align: center;
  font-weight: 400;
  text-align: center;
  @media (min-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 430px) {
    margin-bottom: 16px;
  } ;
`;

export const Hr = styled.hr`
  color: #fefefe;
  background-color: #fefefe;
  height: 0.5;
`;

export const Divider = styled.div`
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 16px;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-shrink: 0;
  font: 500 20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  padding: 24px 24px 20px 24px;
`;

export const ModalBody = styled.div`
  flex-grow: 2;
  flex-shrink: 2;
  display: block;
  font: 400 14px / 20px Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  padding: 0 24px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-shrink: 0;
  box-pack: end;
  -webkit-box-pack: end;
  justify-content: flex-end;
  padding: 24px 24px 16px 24px;
  @media (max-width: 430px) {
    align-items: center;
    justify-content: center;
    padding: 24px;
    flex-direction: column;
  } ;
`;

export const Close = styled.div`
  flex-shrink: 0;
  display: block;
  color: rgba(0, 0, 0, 0.54);
  fill: rgba(0, 0, 0, 0.54);
  -webkit-user-select: none;
  transition: background 0.3s;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  flex-shrink: 0;
  height: 48px;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  width: 48px;
  z-index: 0;
`;

export const Required = styled.span`
  color: red;
`;

export const PostLeft = styled.div`
  width: 50%;
  height: 100%;
  padding: 3px;
  overflow-x: scroll;
  overflow-y: scroll;
  @media (max-width: 430px) {
    width: 100%;
    display: ${(props) => (props.showLeft ? "block" : "none")};
  }
`;

export const PostRight = styled.div`
  width: 50%;
  height: 100%;
  padding: 3px;
  overflow-x: scroll;
  overflow-y: scroll;
  @media (max-width: 430px) {
    width: 100%;
    display: ${(props) => (props.showRight ? "block" : "none")};
    border-left: none;
  }
`;

export const Error = styled.p`
  width: 100%;
  font-size: 9px;
  color: red;
  font-weight: 300;
  font-family: sans-serif;
  margin-bottom: 5px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: 769px) {
    max-width: 100vw;
  }
  max-width: 1128px;
  position: relative;
`;

export const Main = styled.main`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
  color: #000;
  font-size: 1rem;
  display: block;
  // text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;
