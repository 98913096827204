const longMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const shortMonthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getMonthYear = (date) => {
  if (date == undefined) {
    return date;
  }

  var dt = new Date(date);
  return dt.toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });
};

export const parseDateTime = (dt) => {
  const [dateRelated, timeRelated] = dt.includes(",")
    ? dt.split(", ")
    : dt.split(" ");
  const [day, month, year] = dateRelated.split("/");
  const [hours, minutes, seconds] = timeRelated.split(":");
  const date = new Date(year, month - 1, +day, +hours, +minutes, +seconds);
  return date;
};

export const getMessageTS = (dt) => {
  if (dt == undefined) {
    return dt;
  }
  const date = parseDateTime(dt);
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const month = date.getUTCMonth();
  const short_year = date.getFullYear() % 100;
  const hour = hours > 12 ? hours - 12 : hours;
  const am_pm = hours > 12 ? "PM" : "AM";
  const hour_formatted = String(hour).length == 1 ? `0${hour}` : `${hour}`;
  const min_formatted =
    String(minutes).length == 1 ? `0${minutes}` : `${minutes}`;

  return `${hour_formatted}:${min_formatted} ${am_pm} ${shortMonthNames[month]} ${short_year}`;
};

// This function calculates the number of days, hours, minutes, weeks and months between a given date and today
export const daysBetween = (utcTimestamp) => {
  // Convert the UTC timestamp to a local date object
  const date = new Date(utcTimestamp);
  const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

  // Return null if no date is provided
  if (!localDate) {
    return null;
  }

  // Calculate time difference between the input date and now
  const now = new Date();
  const timeDiff = Math.abs(now.getTime() - localDate.getTime());

  // Calculate days, hours, and minutes from the time difference
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
  const minutes = Math.floor((timeDiff / (1000 * 60)) % 60);

  // Calculate weeks and months from the number of days
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / (4 * 7));

  // Return a string that indicates how long ago or how far in the future the date is
  if (months === 1) {
    return "1 month ago";
  } else if (months > 1) {
    return `${months} months ago`;
  } else if (weeks === 1) {
    return "1 week ago";
  } else if (weeks > 1) {
    return `${weeks} weeks ago`;
  } else if (days === 1) {
    return "1 day ago";
  } else if (days === 0) {
    if (hours === 0 && minutes === 0) {
      return "Just now";
    } else if (hours === 0 && minutes < 60) {
      return "1 hour ago";
    } else if (hours === 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${hours} hours ago`;
    }
  } else if (days === -1) {
    return "Tomorrow";
  } else if (days === -2) {
    return "Day after tomorrow";
  } else if (days < -2) {
    return `${Math.abs(days)} days later`;
  } else {
    return `${days} days ago`;
  }
};

export const extractDateFromDateTime = (datetimeString) => {
  const datetime = new Date(datetimeString);
  const year = datetime.getFullYear();
  const month = datetime.getMonth() + 1;
  const day = datetime.getDate();
  return `${year}-${month < 10 ? "0" + month : month}-${
    day < 10 ? "0" + day : day
  }`;
};
