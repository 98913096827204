import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import OuterContainer from "../components/layout/OuterContainer";
import TextInput from "../components/common/TextInput";
import Button from "../components/common/Button";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";

const ResetPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  color: #333;
  flex-shrink: 0;
  min-height: 0;
  width: 350px;
  max-width: 98%;
  gap: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 10px;
`;

const LogoMain = styled.h1`
  color: #00a87e;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
`;

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get("token");
    setToken(token);
  }, [location.search]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const endpoint = API_CONSTANT_MAP.reset_password;
    const result = request("POST", endpoint, {
      password: password,
      token: token,
    });

    result
      .then((result) => {
        if (result.data.success) {
          navigate("/signin");
        }
      })
      .catch(() => {});
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <OuterContainer>
      <ResetPasswordContainer>
        <LogoMain onClick={handleLogoClick}>Swap</LogoMain>
        <Title>New Password</Title>
        {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
        <TextInput
          value={password}
          name={"password"}
          label={"New Password"}
          onChange={handlePasswordChange}
          type={"password"}
          placeholder={"Enter your new password"}
        />
        <TextInput
          value={confirmPassword}
          name={"confirmPassword"}
          label={"Confirm password"}
          onChange={handleConfirmPasswordChange}
          type={"password"}
          placeholder={"Confirm your new password"}
        />
        <Button
          type="submit"
          style={{ margin: "20px 0px" }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </ResetPasswordContainer>
    </OuterContainer>
  );
};

export default ResetPassword;
