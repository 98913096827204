const searchReducer = (
  state = {
    query: "",
    zipcode: 94568,
    distance: 40,
    sortBy: "mostRecent",
    categories: [],
    itemConditions: [],
    location: "Dublin",
    zipcodeResults: [],
    zipcodeDistChanged: false,
  },
  action
) => {
  switch (action.type) {
    case "SEARCH":
      return {
        ...state,
        query: action.payload.query,
      };
    case "ZIPCODE":
      return {
        ...state,
        zipcode: parseInt(action.payload.zipcode),
      };
    case "LOCATION":
      return {
        ...state,
        location: action.payload.location,
      };

    case "SORT":
      return {
        ...state,
        sortBy: action.payload.sortBy,
      };

    case "DISTANCE":
      return {
        ...state,
        distance: parseInt(action.payload.distance),
      };
    case "SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload.categories,
      };
    case "ITEM_CONDITIONS":
      return {
        ...state,
        itemConditions: action.payload.itemConditions,
      };

    case "ZIPCODE_RESULTS":
      return {
        ...state,
        zipcodeResults: action.payload.zipcodeResults,
      };

    case "ZIPCODE_DISTANCE_CHANGED":
      return {
        ...state,
        zipcodeDistChanged: action.payload.zipcodeDistChanged,
      };
    default:
      return state;
  }
};

export default searchReducer;
