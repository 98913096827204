import React from "react";
//Router
import { BrowserRouter } from "react-router-dom";
import history from "./services/history/BrowserHistory";
import Paths from "./routes/index";
import { SkeletonTheme } from "react-loading-skeleton";
import NavBar from "./components/layout/NavBar";
import Meta from "./components/Meta";

function App() {
  return (
    <Meta>
      <SkeletonTheme baseColor="#ebebeb" highlightColor="#f5f5f5">
        <BrowserRouter history={history}>
          <Paths history={history}>
            <NavBar />
          </Paths>
        </BrowserRouter>
      </SkeletonTheme>
    </Meta>
  );
}

export default App;
