export const authHeader = () => {
  try {
    const store = JSON.parse(localStorage.getItem("store"));
    if (store && store.user && store.user.token) {
      return { Authorization: "Bearer " + store.user.token };
    }
  } catch (error) {
    console.error("Error parsing store data:", error);
  }
  return {};
};

export const get_token = () => {
  try {
    const store = JSON.parse(localStorage.getItem("store"));
    if (store && store.user && store.user.token) {
      return store.user.token;
    }
  } catch (error) {
    console.error("Error retrieving user token:", error);
  }
  return null;
};
