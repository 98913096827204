import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { validateField } from "../utils/helpers/validate";
import NavBar from "../components/layout/NavBar";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import { signup } from "../store/actions";
import Loader from "../components/Loading";
import OuterContainer from "../components/layout/OuterContainer";
import TextInput from "../components/common/TextInput";
import Button from "../components/common/Button";
import styled from "styled-components";
import Meta from "../components/Meta";

const SignUpContainer = styled.div`
  background: #fff;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: block;
  flex-shrink: 0;
  margin: 0 auto;
  min-height: 0;
  width: 450px;
  max-width: 98%;
  padding: 48px 40px 36px;
`;

const Logo = styled.div`
  font-size: 18px;
  color: #00a87e;
  display: flex;
  justify-content: center;
  width: 100%;
  font-weight: 500;
  margin: 10px 0px 30px 0px;
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
`;

const Header = styled.h1`
  padding: 10px 0px;
  color: #202124;
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px 0px 0px;

  & > span {
    font-size: 0.9rem;
    padding: 5px;
    font-weight: 500;
  }

  & .link {
    color: #00a87e;
    cursor: pointer;
    font-weight: 500;
  }
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin: 3px 0px 10px 0px;
`;

const SignUp = (props) => {
  const navigate = useNavigate();
  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
  };
  const [formValues, setFormValues] = useState(initialState);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });

    // Update form errors as you type
    const errors = validateField(name, value);
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errors ? errors : null,
    }));
  };

  useEffect(() => {
    if (isSubmit) {
      props.signup({ type: "SIGNUP_INIT" });

      const endpoint = API_CONSTANT_MAP.signup;
      const result = request("POST", endpoint, formValues);

      result
        .then((result) => {
          if ("token" in result.data) {
            props.signup({
              type: "SIGNUP_SUCCESS",
              payload: result.data,
            });
            navigate("/");
          }
        })
        .catch((response) => {
          if (response.error) {
            // User already exists
            setErrorMsg(response.error.message);
          }
          props.signup({
            type: "SIGNUP_FAILURE",
          });
        });
    }
  }, [isSubmit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      setIsSubmit(!isSubmit);
    }
  };

  const handleHaveAccount = (e) => {
    e.preventDefault();
    navigate("/signin");
  };

  const validate = (values) => {
    const errors = {};
    const first_name = validateField("name", values.first_name);
    const last_name = validateField("name", values.last_name);
    const email = validateField("email", values.email);
    const password = validateField("password", values.password);

    if (first_name) {
      errors.first_name = first_name;
    }
    if (last_name) {
      errors.last_name = last_name;
    }
    if (email) {
      errors.email = email;
    }
    if (password) {
      errors.password = password;
    }

    return errors;
  };

  return (
    <Meta
      pageMeta={{
        title: "Sign up - Swapthings",
      }}
    >
      <NavBar user={props.state.user} />
      <OuterContainer>
        <SignUpContainer>
          <Header>
            <span>Sign up</span>
          </Header>
          <Logo>
            <span>Get started on Swap</span>
          </Logo>
          <form onSubmit={handleSubmit}>
            <TextInput
              value={formValues.first_name}
              name={"first_name"}
              label={"First Name"}
              onChange={handleChange}
            />
            {formErrors.first_name && (
              <ErrorMessage>{formErrors.first_name}</ErrorMessage>
            )}
            <TextInput
              value={formValues.last_name}
              name={"last_name"}
              label={"Last Name"}
              onChange={handleChange}
            />
            {formErrors.last_name && (
              <ErrorMessage>{formErrors.last_name}</ErrorMessage>
            )}
            <TextInput
              value={formValues.email}
              name={"email"}
              label={"Email address"}
              onChange={handleChange}
              type={"text"}
            />
            {formErrors.email && (
              <ErrorMessage>{formErrors.email}</ErrorMessage>
            )}
            <TextInput
              value={formValues.password}
              name={"password"}
              label={"Password"}
              onChange={handleChange}
              type={"password"}
              placeholder={"Use at least 6 characters"}
            />
            {formErrors.password && (
              <ErrorMessage>{formErrors.password}</ErrorMessage>
            )}
            <Button type="submit" style={{ margin: "20px 0px" }}>
              <span>Sign up</span>
            </Button>
            {errorMsg && <ErrorMessage>{errorMsg}</ErrorMessage>}
            <Footer>
              <span>
                Have an account?{" "}
                <span class="link" onClick={handleHaveAccount}>
                  Sign in
                </span>
              </span>
            </Footer>
          </form>
        </SignUpContainer>
      </OuterContainer>
      <Loader animating={props.state.user.isLoading} />
    </Meta>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ signup }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
