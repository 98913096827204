import axios from "axios";

  export const uploadToS3 = async (presigned_url, upload_file) => {
    try {
      const formData = new FormData();
      const { fields, url } = presigned_url;
  
      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
  
      const file = new File([upload_file], upload_file.name);
      formData.append("file", file);
  
      const response = await axios.post(url, formData);
      return response;
    } catch (error) {
      throw new Error(`Error uploading file to S3: ${error.message}`);
    }
  };
  

// ref: https://www.dtreelabs.com/blog/s3-direct-file-upload-using-presigned-url-from-react-and-rails