const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "SIGNUP_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "SIGNUP_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        avatar: action.payload.user.avatar,
        token: action.payload.token,
        user_id: action.payload.user.id,
        unread_message_count: 0,
      };
    case "SIGNUP_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "SIGNIN_INIT":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "SIGNIN_SUCCESS":
      return {
        ...state,
        isLoading: false,
        isError: false,
        first_name: action.payload.user.first_name,
        last_name: action.payload.user.last_name,
        avatar: action.payload.user.avatar,
        token: action.payload.token,
        user_id: action.payload.user.id,
        unread_message_count: action.payload.user.unread_message_count,
      };
    case "SIGNIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        isError: true,
      };
    case "SIGNOUT":
      return {};
    default:
      return state;
  }
};

export default authReducer;
