import styled from "styled-components";
import { size } from "../../utils/constants/css";

const MainContainerWrapper = styled.div`
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1225px;
  display: flex;
  justify-content: center;
  z-index: 1;
  @media screen and (max-width: ${size.tablet}px) {
    margin-top: 75px;
    width: 96%;
  }
`;

const MainContainer = ({ className, children }) => {
  return (
    <MainContainerWrapper className={className}>
      {children}
    </MainContainerWrapper>
  );
};

export default MainContainer;
