import React from "react";
import Helmet from "react-helmet";
import {
  default_description,
  default_title,
  default_keywords,
} from "../utils/constants/meta";

function Meta({ pageMeta, children }) {
  const defaultMeta = {
    title: default_title,
    description: default_description,
    keywords: default_keywords,
  };

  const meta = {
    ...defaultMeta,
    ...pageMeta,
  };
  return (
    <>
      <Helmet defer={true}>
        <title>{`${meta.title}`}</title>
        <meta charSet="UTF-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="author" content="vinay emmadi" />
        <meta name="description" content={meta.description} />

        {meta.keywords && (
          <meta name="keywords" content={meta.keywords.join(",")} />
        )}
      </Helmet>
      <div style={{ width: "100%" }}>{children}</div>
    </>
  );
}

export default Meta;
