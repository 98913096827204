import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import OuterContainer from "../components/layout/OuterContainer";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import TextInput from "../components/common/TextInput";
import Button from "../components/common/Button";
import Meta from "../components/Meta";

const ForgotPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  min-height: 0;
  width: 350px;
  max-width: 98%;
  gap: 20px;

  & .link {
    color: #00a87e;
    cursor: pointer;
  }
`;

const Title = styled.h1`
  font-size: 24px;
`;

const LogoMain = styled.h1`
  color: #00a87e;
  cursor: pointer;
  font-size: 24px;
  font-weight: 400;
`;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [resend, setResend] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleVerifyEmail = (e) => {
    if (email != "") {
      const endpoint = API_CONSTANT_MAP.forgot_password;
      const result = request("POST", endpoint, { email: email });

      result
        .then((result) => {
          setResend(result.data.success);
        })
        .catch(() => {});
    }
  };

  const handleHaveAccount = (e) => {
    e.preventDefault();
    navigate("/signin");
  };

  const handleLogoClick = (e) => {
    e.preventDefault();
    navigate("/");
  };

  const handleResend = (e) => {
    e.preventDefault();
    setResend(false);
    setEmail("");
  };

  return (
    <Meta
      pageMeta={{
        title: "Forgot Password - Swapthings",
      }}
    >
      <OuterContainer>
        <ForgotPasswordContainer>
          <LogoMain onClick={handleLogoClick}>Swap</LogoMain>
          <Title>Forgot Password</Title>
          {!resend ? (
            <>
              <p style={{ fontSize: "14px" }}>
                We will send you instructions to reset your password to the
                email address provided below. Please check your email and follow
                the reset instructions.
              </p>
              <TextInput
                value={email}
                name={"email"}
                label={"Email address"}
                onChange={handleEmailChange}
                type={"email"}
                placeholder={"Enter your email"}
              />
              <Button
                type="submit"
                style={{ margin: "20px 0px" }}
                onClick={handleVerifyEmail}
              >
                Verify Email
              </Button>
            </>
          ) : (
            <>
              <p style={{ fontSize: "14px" }}>
                We've sent a password reset email to {email}. Please click the
                link in the email to set your new password.
              </p>
              <Button
                type="submit"
                style={{ margin: "20px 0px" }}
                onClick={handleResend}
              >
                Resend
              </Button>
            </>
          )}
          <span class="link" onClick={handleHaveAccount}>
            Sign in
          </span>
        </ForgotPasswordContainer>
      </OuterContainer>
    </Meta>
  );
};

export default ForgotPassword;
