export const default_description =
  "SwapThings - Swap used books. Join our community and browse, search, and select items you want to swap. Negotiate the exchange directly with other users and enjoy a convenient and eco-friendly way to refresh your collection while reducing waste and saving money.";
export const default_keywords = [
  "SwapThings",
  "swap books",
  "trade books",
  "trade used books",
  "sell used books",
  "swap",
  "exchange books",
  // "swap toys",
  // "trade toys",
  // "trade used toys",
  // "exchange toys",
  // "swap games",
  // "trade games",
  // "exchange games",
  // "trade paperback",
  "used books online",
];
export const default_title = "SwapThings - Swap Used Books";
