import React from "react";
import styled from "styled-components";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  width: 400px;
  max-height: 500px;
  display: flex;
  flex-direction: column;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  border: none;
  cursor: pointer;
  color: #efefef;
  background-color: rgba(0, 0, 0, 0.7);
`;
const ModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 20px;
  justify-content: center;
`;

const Modal = (props) => {
  const { isOpen, onClose, title } = props;

  if (!isOpen) return null;

  return (
    <ModalBackground onClick={onClose} aria-hidden="true">
      <ModalContent onClick={(e) => e.stopPropagation()}>
        {title && <ModalTitle>{title}</ModalTitle>}
        <CloseButton onClick={onClose}>x</CloseButton>
        {props.children}
      </ModalContent>
    </ModalBackground>
  );
};

export default Modal;
