import React, { useState } from "react";
import styled from "styled-components";
import { FiMoreHorizontal, FiTrash2, FiEdit } from "react-icons/fi";
import { VscPreview } from "react-icons/vsc";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ListItemContainer = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : "100%")};
  max-width: 225px;
  height: ${(props) => (props.height ? `${props.height}px` : "220px")};
  background-color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: box-shadow 0.2s ease-in-out;
  list-style-type: none;
  display: block;
  position: relative;

  &:hover .manage-button {
    opacity: 1;
  }
`;

const UnSaveBtn = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: none;
  cursor: pointer;
  color: #efefef;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ListItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px 0px;

  & > h1 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-top: 0.6rem;
    margin-bottom: 0px;
    font-weight: 500;
    text-shadow: 0px 0px 1px rgb(44, 35, 57, 0.4);
    color: #2c2339;
    overflow: hidden;
    white-space: nowrap; /* Prevents the text from wrapping to a new line */
    text-overflow: ellipsis; /* Shows ellipsis (...) when text overflows */
  }

  & > span {
    font-size: 0.8rem;
    font-weight: 500;
    color: #666;
  }
`;

const ListingImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 0.9;
  min-width: 100%;
  display: block;
  height: 100%;
  position: relative;
  overflow: hidden;

  & > img,
  .imgskeleton {
    width: 100%;
    border-radius: 1rem;
    object-fit: cover;
    object-position: top;
    position: absolute;
    inset: 0px;
    box-sizing: border-box;
    padding: 0px;
    border: none;
    margin: auto;
    display: block;
    width: 0px;
    height: 0px;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
  }

  &:hover > img {
    opacity: 0.9;
  }
`;
const ListingActions = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2f2f2;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;

  .manage-button {
    cursor: pointer;
  }
`;

const MenuContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  width: 150px;
  z-index: 1;

  & > button {
    border: none;
    background-color: transparent;
    padding: 4px;
    font-size: 14px;
    color: #121212;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &:hover {
      background-color: #f2f2f2;
    }

    & > svg {
      margin-right: 5px;
    }
  }
`;

export const Listing = ({
  id,
  imageUrl,
  title,
  location,
  created_at,
  onClick,
  onRemove,
  onEdit,
  onDelete,
  onView,
  listingActions = false,
  onlyImage = false,
  width,
  height,
}) => {
  const [showMenu, setShowMenu] = useState(false);

  const handleMenuClick = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  return (
    <ListItemContainer
      key={id}
      onClick={onClick ? () => onClick(id) : undefined}
      onlyImage={onlyImage}
      width={width}
      height={height}
    >
      <ListingImageContainer>
        <img src={imageUrl} alt={title} />
      </ListingImageContainer>

      <ListItemInfo>
        <h1
          style={{
            fontSize: "1rem",
            lineHeight: "1.25",
          }}
        >
          {title}
        </h1>
        <span>{location}</span>
      </ListItemInfo>
      {onRemove && <UnSaveBtn onClick={(e) => onRemove(e, id)}>x</UnSaveBtn>}

      {listingActions && (
        <ListingActions onClick={handleMenuClick} className="manage-button">
          <FiMoreHorizontal size={18} />
          {showMenu && (
            <MenuContainer>
              {onEdit && (
                <button onClick={(e) => onEdit(e, id)}>
                  <FiEdit size={14} />
                  Edit
                </button>
              )}
              {onDelete && (
                <button onClick={(e) => onDelete(e, id)}>
                  <FiTrash2 size={14} />
                  Remove
                </button>
              )}
              {onView && (
                <button onClick={(e) => onView(e, id)}>
                  <VscPreview size={14} />
                  View Listing
                </button>
              )}
            </MenuContainer>
          )}
        </ListingActions>
      )}
    </ListItemContainer>
  );
};

export const ListingSkeleton = ({
  id,
  onlyImage = false,
  width,
  height,
  title,
  created_at,
}) => {
  return (
    <ListItemContainer
      key={id}
      onlyImage={onlyImage}
      width={width}
      height={height}
    >
      {/* <img src={imageUrl} /> */}
      <ListingImageContainer>
        <Skeleton className="imgskeleton" />
      </ListingImageContainer>

      {/* <ListItemInfo>
        <p
          style={{ color: "#050505", fontWeight: "400", fontSize: ".9375rem" }}
        >
          {title && <Skeleton />}
        </p>

        {created_at && <Skeleton />}
      </ListItemInfo> */}
    </ListItemContainer>
  );
};
