import React, { useState, useEffect } from "react";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import OuterContainer from "../components/layout/OuterContainer";
import Loader from "../components/Loading";
import { request } from "../services/networking/request";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { Listing, ListingSkeleton } from "../components/Listing";
import styled from "styled-components";
import { extractDateFromDateTime } from "../utils/helpers/date";
import { useNavigate } from "react-router-dom";
import { size } from "../utils/constants/css";
import Button from "../components/common/Button";
import Meta from "../components/Meta";

const ListingsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(175px, 1fr));
  column-gap: 35px;
  row-gap: 100px;
  max-width: 100%;
  margin: 0 auto;

  @media screen and (max-width: ${size.tablet}px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    column-gap: 20px;
    row-gap: 70px;
  }
`;

const Main = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const NoListingsContainer = styled.div`
  margin: 100px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const YourListings = (props) => {
  const navigate = useNavigate();
  const [listings, setListings] = useState([]);
  const [listingsLoaded, setListingsLoaded] = useState(false);

  useEffect(() => {
    const endpoint = API_CONSTANT_MAP.get_your_listings;
    const result = request("POST", endpoint);
    result
      .then((result) => {
        setListings(result.data.listings);
        setListingsLoaded(true);
      })
      .catch(() => {
        setListingsLoaded(true);
      });
  }, []);

  const handleEdit = (e, listing_id) => {
    e.preventDefault();
    navigate(`/edit?listing_id=${listing_id}`);
  };

  const handleView = (e, listing_id) => {
    e.preventDefault();
    navigate(`/listing/details/${listing_id}`);
  };

  const handleRemove = (event, listing_id) => {
    event.stopPropagation();

    const endpoint = API_CONSTANT_MAP.delete_listing;
    const result = request("POST", endpoint, { listing_id: listing_id });
    result
      .then((result) => {
        if (result.data.success) {
          const newListings = listings.filter(
            (listing) => listing.id !== listing_id
          );
          // Update the favorites state with the new array
          setListings(newListings);
        }
      })
      .catch(() => {});
  };

  const handleListClick = () => {
    navigate("/swap");
  };
  return (
    <Meta
      pageMeta={{
        title: "My Listings - Swapthings",
      }}
    >
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Main>
          {listingsLoaded && listings.length == 0 && (
            <NoListingsContainer>
              <h1 style={{ fontWeight: "500" }}>No listings to Swap</h1>
              <span>
                When you list an item for <br />
                swap, you’ll see it here.
              </span>
              <Button
                type="submit"
                style={{
                  margin: "20px 0px",
                  backgroundColor: "#00a87e",
                  border: "none",
                }}
                onClick={handleListClick}
              >
                Swap an Item
              </Button>
            </NoListingsContainer>
          )}
          {listingsLoaded && listings.length > 0 && (
            <ListingsContainer>
              {listings.map((listing) => (
                <Listing
                  id={listing.id}
                  title={listing.title}
                  description={listing.description}
                  imageUrl={listing.image_urls[0]}
                  created_at={extractDateFromDateTime(listing.created_at)}
                  onEdit={handleEdit}
                  onDelete={handleRemove}
                  onView={handleView}
                  listingActions={true}
                />
              ))}
            </ListingsContainer>
          )}
          {!listingsLoaded && (
            <ListingsContainer>
              {Array(8)
                .fill(0)
                .map((_, i) => (
                  <ListingSkeleton id={i} title={true} created_at={true} />
                ))}
            </ListingsContainer>
          )}
        </Main>
      </OuterContainer>
    </Meta>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(YourListings);
