import React from "react";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import NotFoundPage from "../pages/NotFoundPage";
import SignUp from "../pages/SignUp";
import SignIn from "../pages/SignIn";
import Home from "../pages/Home";
import Trade from "../pages/Trade";
import ListingDetails from "../pages/ListingDetails";
import Inbox from "../pages/Inbox";
import SignOut from "../pages/SignOut";
import Account from "../pages/Account";
import Favorites from "../pages/Favourites";
import ChatMessenger from "../pages/ChatMessenger";
import Search from "../pages/Search";
import YourListings from "../pages/YourListings";
import EditListing from "../pages/EditListing";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import HowItWorks from "../pages/HowItWorks";
import { ProtectedRoute } from "./helpers";
import EmailConfirmation from "../pages/EmailConfirmation";

function Paths(props) {
  const { history } = props;
  return (
    <Routes>
      <Route path="/" exact element={<Home />} history={history} />
      <Route path="/home" exact element={<Home />} />
      <Route path="/signup" exact element={<SignUp />} />
      <Route path="/signin" exact element={<SignIn />} />
      <Route path="/howitworks" exact element={<HowItWorks />} />
      <Route
        path="/swap"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <Trade />
          </ProtectedRoute>
        }
      />
      <Route
        path="/account"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <Account />
          </ProtectedRoute>
        }
      />
      <Route path="/listing/details/:id" exact element={<ListingDetails />} />
      <Route path="/search" exact element={<Search />} />
      <Route path="/listings" exact element={<YourListings />} />
      <Route
        path="/edit/"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <EditListing />
          </ProtectedRoute>
        }
      />

      <Route
        path="/favourites"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <Favorites />
          </ProtectedRoute>
        }
      />

      <Route
        path="/inbox/message/:id"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <ChatMessenger />
          </ProtectedRoute>
        }
      />
      <Route
        path="/inbox"
        exact
        element={
          <ProtectedRoute user={props.state.user} redirectPath="/signin">
            <Inbox />
          </ProtectedRoute>
        }
      />
      <Route path="/signout" exact element={<SignOut />} />
      <Route path="/password/reset/start" exact element={<ForgotPassword />} />
      <Route path="/password/reset/done" element={<ResetPassword />} />
      <Route path="/email/confirmation" exact element={<EmailConfirmation />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Paths);

// Used this article as reference:
// https://medium.com/javascript-in-plain-english/routing-and-navigation-in-react-cffc26e8a389
// https://stackoverflow.com/questions/37696391/multiple-params-with-react-router
