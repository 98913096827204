import React, { useState, useEffect, useRef } from "react";
import NavBar from "../components/layout/NavBar";
import { connect } from "react-redux";
import styled from "styled-components";
import RoundedImage from "../components/RoundedImage";
import { API_CONSTANT_MAP } from "../utils/constants/endpoints";
import { request } from "../services/networking/request";
import Loader from "../components/Loading";
import Button from "../components/common/Button";
import Modal from "../components/Modal";
import unknown_gw from "../assets/unknown_gw.png";
import FileUploader from "../components/FileUploader";
import { uploadToS3 } from "../utils/helpers/s3";
import OuterContainer from "../components/layout/OuterContainer";

const Input = styled.input`
  border: 1px solid #ecedf1;
  font-size: 14px;
  color: #222;
  width: 100%;
  padding: 10px 5px;
  border-radius: 5px;

  &:focus {
    outline: none;
    border-color: #222;
  }
`;

const Header = styled.div`
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
`;
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  width: 500px;
  // background-color: #fdfdfd;
`;

const Edit = styled.span`
  color: #00a87e;
  font-weight: 500;
  font-size: 16px;
`;

const PersonDetail = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;

  & > h2 {
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.64px;
  }
`;

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0px;
  margin-top: 50px;

  & > li {
    height: 50px;
    font-size: 16px;
    width: 100%;
    border-bottom: 0.5px solid #cfcfcf;
  }

  & > li > button {
    width: 100%;
    height: 100%;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;

  border: 1px solid #ecedf1;
  font-size: 14px;
  color: #222;
  border-radius: 5px;
  padding: 2px;

  &:focus-within {
    border-color: #222;
    background-color: #fff;
  }
`;

const PasswordInput = styled.input`
  width: 100%;
  border: none;
  padding: 10px 5px;
`;

const ShowHideButton = styled.button`
  padding: 10px 5px;
  border: none;
  background-color: #fff;
  cursor: pointer;
  color: #00a87e;
  font-weight: 500;
  font-size: 16px;
`;

const Account = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState({});
  const [temp, setTemp] = useState({});
  const [submit, setSubmit] = useState(false);
  const [editorVisible, setEditorVisibility] = useState({
    first_name_editor: false,
    last_name_editor: false,
    password_editor: false,
  });
  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [apiMessage, setApiMessage] = useState("");

  const handleToggleCurrentPassword = () => {
    setShowCurrentPassword(
      (prevShowCurrentPassword) => !prevShowCurrentPassword
    );
  };

  const handleToggleNewPassword = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };

  const handleToggleConfirmPassword = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };

  useEffect(() => {
    if (!passwordModal) return;
    const handlePasswordChange = () => {
      const currentPassword = currentPasswordRef.current.value;
      const newPassword = newPasswordRef.current.value;
      const confirmPassword = confirmPasswordRef.current.value;
      // Compare the new password and confirm password
      setPasswordsMatch(newPassword === confirmPassword);

      // Validate the length of the password to be more than 8 characters
      setIsPasswordValid(newPassword.length > 3 && currentPassword.length > 3);
    };

    if (newPasswordRef.current && confirmPasswordRef.current) {
      currentPasswordRef.current.addEventListener(
        "input",
        handlePasswordChange
      );
      newPasswordRef.current.addEventListener("input", handlePasswordChange);
      confirmPasswordRef.current.addEventListener(
        "input",
        handlePasswordChange
      );
    }

    // Clean up the event listeners
    return () => {
      if (newPasswordRef.current && confirmPasswordRef.current) {
        currentPasswordRef.current.removeEventListener(
          "input",
          handlePasswordChange
        );
        newPasswordRef.current.removeEventListener(
          "input",
          handlePasswordChange
        );
        confirmPasswordRef.current.removeEventListener(
          "input",
          handlePasswordChange
        );
      }
    };
  }, [passwordModal]);

  useEffect(() => {
    if (submit) {
      setIsLoading(true);
      const endpoint = API_CONSTANT_MAP.update_account_details;
      const result = request("POST", endpoint, user);
      result
        .then((result) => {
          let data = result.data;
          if (!("error" in data)) {
            // alert("Profile updated successfully");
          } else {
            // alert("Something went wrong");
          }
          setIsLoading(false);
        })
        .catch(() => {
          // alert("Something went wrong");
          setIsLoading(false);
        });
      setSubmit(false);
    }
  }, [submit]);

  const handlePasswordSubmit = () => {
    const currentPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;

    const payload = {
      password: currentPassword,
      new_password: newPassword,
    };

    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.update_account_password;
    const result = request("POST", endpoint, payload);
    result
      .then((result) => {
        let data = result.data;
        console.log(data);
        if (!("error" in data)) {
          alert("Profile updated successfully");
        } else {
          alert("Something went wrong1");
        }
        setIsLoading(false);
      })
      .catch(() => {
        alert("Something went wrong2");
        setIsLoading(false);
      });
  };

  const openEditor = (editor) => {
    if (editor == "password_editor") {
      setPasswordModal(!passwordModal);
    }
    setTemp(user);
    setEditorVisibility({ ...editorVisible, [editor]: true });
  };

  const closeEditor = (editor) => {
    setEditorVisibility({ ...editorVisible, [editor]: false });
  };

  const handleSubmit = (e, editor) => {
    Promise.resolve()
      .then(() => setUser({ ...user, ...temp }))
      .then(() => setSubmit(true));
    closeEditor(editor);
  };

  const handleFieldChange = (e) => {
    e.preventDefault();
    setTemp({ ...temp, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setIsLoading(true);
    const endpoint = API_CONSTANT_MAP.get_account_details;
    const result = request("POST", endpoint);
    result
      .then((result) => {
        setUser(result.data.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  const handleFile = (file) => {
    const endpoint = API_CONSTANT_MAP.get_avatar_presigned_url;
    const result = request("POST", endpoint, { image_url: file.name });
    result
      .then((result) => {
        const { presigned_url, image_url } = result.data.data;
        return Promise.all([uploadToS3(presigned_url, file), image_url]);
      })
      .then(([s3_response, image_url]) => {
        if (s3_response.status == 204 || s3_response.status == 200) {
          setUser({ ...user, avatar: image_url });
        }
      })
      .catch(() => {
        alert("Something went wrong");
      });
  };

  return (
    <div>
      <NavBar user={props.state.user} />
      <OuterContainer>
        <Main>
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "20px" }}
          >
            <div>
              <RoundedImage
                imageHeight={120}
                imageWidth={120}
                borderRadiusPct={50}
                image={user?.avatar ? user.avatar : unknown_gw}
              />
              <FileUploader
                buttontext="Update Profile Picture"
                handleFile={handleFile}
              />
            </div>

            <PersonDetail>
              <h2>
                {user.first_name} {user.last_name}
              </h2>
            </PersonDetail>
          </div>

          {/* <h2 style={{ marginTop: "20px", fontSize: "22px" }}>Account</h2> */}
          {user && (
            <ListContainer>
              {user?.first_name ? (
                <li>
                  <button onClick={() => openEditor("first_name_editor")}>
                    <span>{user.first_name}</span>
                    <Edit>Edit</Edit>
                  </button>
                </li>
              ) : (
                <li>
                  <button>{"Unknown"}</button>
                </li>
              )}
              {user?.last_name ? (
                <li>
                  <button onClick={() => openEditor("last_name_editor")}>
                    <span>{user.last_name}</span>
                    <Edit>Edit</Edit>
                  </button>
                </li>
              ) : (
                <li>
                  <button>{"Unknown"}</button>
                </li>
              )}
              {user?.email ? (
                <li>
                  <button>
                    <span>{user.email}</span>
                    <Edit>Edit</Edit>
                  </button>
                </li>
              ) : (
                <li>
                  <button>{"Unknown"}</button>
                </li>
              )}
              {
                <li>
                  <button onClick={() => openEditor("password_editor")}>
                    <span>Password</span>
                    <Edit>Edit</Edit>
                  </button>
                </li>
              }
            </ListContainer>
          )}
        </Main>
      </OuterContainer>
      <Loader animating={isLoading} />

      <Modal
        isOpen={editorVisible["first_name_editor"]}
        onClose={() => closeEditor("first_name_editor")}
      >
        <Header>
          <h2 style={{ fontSize: "19px" }}>Enter First Name</h2>
        </Header>
        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
          <label style={{ width: "100%" }}>
            First Name
            <br />
            <Input
              id="first_name"
              value={temp.first_name || ""}
              type="text"
              name="first_name"
              onChange={(e) => handleFieldChange(e)}
            />
          </label>
        </div>
        <Button
          style={{ marginTop: "20px" }}
          onClick={(e) => handleSubmit(e, "first_name_editor")}
        >
          Save
        </Button>
      </Modal>

      <Modal
        isOpen={editorVisible["last_name_editor"]}
        onClose={() => closeEditor("last_name_editor")}
      >
        <Header>
          <h2 style={{ fontSize: "19px" }}>Enter Last Name</h2>
        </Header>
        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
          <label style={{ width: "100%" }}>
            Last Name
            <br />
            <Input
              id="last_name"
              value={temp.last_name || ""}
              type="text"
              name="last_name"
              onChange={(e) => handleFieldChange(e)}
            />
          </label>
        </div>
        <Button
          style={{ marginTop: "20px" }}
          onClick={(e) => handleSubmit(e, "last_name_editor")}
        >
          Save
        </Button>
      </Modal>

      <Modal
        isOpen={editorVisible["password_editor"]}
        onClose={() => closeEditor("password_editor")}
      >
        <Header>
          <h2 style={{ fontSize: "19px" }}>Change Password</h2>
        </Header>
        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
          <label style={{ width: "100%" }}>
            Current Password
            <br />
            <InputContainer>
              <PasswordInput
                type={showCurrentPassword ? "text" : "password"}
                ref={currentPasswordRef}
              />
              <ShowHideButton
                type="button"
                onClick={handleToggleCurrentPassword}
              >
                {showCurrentPassword ? "Hide" : "Show"}
              </ShowHideButton>
            </InputContainer>
          </label>
        </div>
        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
          <label style={{ width: "100%" }}>
            New Password
            <br />
            <InputContainer>
              <PasswordInput
                type={showNewPassword ? "text" : "password"}
                ref={newPasswordRef}
              />
              <ShowHideButton type="button" onClick={handleToggleNewPassword}>
                {showNewPassword ? "Hide" : "Show"}
              </ShowHideButton>
            </InputContainer>
          </label>
        </div>
        <div style={{ marginBottom: "10px", fontSize: "12px" }}>
          <label style={{ width: "100%" }}>
            Confirm New Password
            <br />
            <InputContainer>
              <PasswordInput
                type={showConfirmPassword ? "text" : "password"}
                ref={confirmPasswordRef}
              />
              <ShowHideButton
                type="button"
                onClick={handleToggleConfirmPassword}
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </ShowHideButton>
            </InputContainer>
          </label>
        </div>
        <p>{apiMessage}</p>
        <Button
          type="submit"
          disabled={!passwordsMatch || !isPasswordValid}
          onClick={(e) => handlePasswordSubmit(e)}
        >
          Change Password
        </Button>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps, null)(Account);
